import { Directive, HostListener, Inject } from '@angular/core';

import { AppSubmenuLinkDirective } from './appsubmenulink.directive';

@Directive({
  selector: '[appSubmenuToggle]'
})
export class AppSubmenuAnchorDirective {
  protected submenulink: AppSubmenuLinkDirective;

  constructor(@Inject(AppSubmenuLinkDirective) submenulink: AppSubmenuLinkDirective) {
    this.submenulink = submenulink;
  }

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(e: any) {
    this.submenulink.hovered = true;
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(e: any) {
    this.submenulink.hovered = false;
  }
}
