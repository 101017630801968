export const environment = {
  envName: 'PROD',
  production: true,
  firebase: {
    apiKey: "AIzaSyCOlClpUoTJPSGTqxSY-AQqSYJZlJYCwQ8",
    authDomain: "dilettapay-prod.firebaseapp.com",
    databaseURL: "https://dilettapay-prod.firebaseio.com",
    projectId: "dilettapay-prod",
    storageBucket: "dilettapay-prod.appspot.com",
    messagingSenderId: "230871216499",
    appId: "1:230871216499:web:b541563d531ddcffa39506",
    measurementId: "G-YKS90W6ER1"
  },

  tenants: [
    {
      tenant: "AgileBank-b6tdp",
      name: "agilebank",
      primaryColor: "0xff2D3491",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/AgileBank/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY1MjIzNjczODc5MCwiYXVkIjoiQWdpbGVCYW5rLWI2dGRwIiwidGVuYW50SWQiOiJBZ2lsZUJhbmstYjZ0ZHAifQ.Er4gg14dnYNyXlGTcILpUjtfcBOu8gJJK-NhPdnm034tlOIp6cOw4pjLOscBKHKhPD753I1b6yFrioSZ0WKW7g",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "DiscoveryBank-9zwbz",
      name: "discoverybank",
      primaryColor: "0xffFE7E13",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/DiscoveryBank/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY0ODc1MDAxMTI1MiwiYXVkIjoiRGlzY292ZXJ5QmFuayIsInRlbmFudElkIjoiRGlzY292ZXJ5QmFuay05endieiJ9.h5kj84SVP-PUaBoycPJoMFqyjNnVXppeWibvFx_n17enwaeakr18lVJXfouFTZziECyeNiM7TPemGftdNzwfgA",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "GFCapital-0igcv",
      name: "gfpay",
      primaryColor: "#7A1A93",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/GFCapital/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY0ODc1MTg3MDA1MiwiYXVkIjoiR0ZDYXBpdGFsIiwidGVuYW50SWQiOiJHRkNhcGl0YWwtMGlnY3YifQ.KZw0MiG6iIRWQ90N-SphbXnqhxXuveYGqGxByYqRhZvz4mO_RoImM3LTSSW3kOk0i236Thkrryicb-DNoXwynQ",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "Otimiza-ei7wo",
      name: "otimiza",
      primaryColor: "#163259",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/Otimiza/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY0ODc0Nzc3MDczNywiYXVkIjoiT3RpbWl6YSIsInRlbmFudElkIjoiT3RpbWl6YS1laTd3byJ9.LzHk4uOS4BqwrtSMStM5Uhxls91Bbywn-gDYmYbW8wyJsnj69oxjWnVkCyhBqZgdFiMdOlqUB8pEGspEa6WaUw",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "SiafBank-fdzht",
      name: "siafbank",
      primaryColor: "0xff68CE00",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/SiafBank/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY0NjM0NDMxMzQwMSwiYXVkIjoiU2lhZkJhbmsiLCJ0ZW5hbnRJZCI6IlNpYWZCYW5rLWZkemh0In0.0bMt98Pzesz9NveT7iLAUWgTD2auhBOtc9VqnorfpePl06AvEeZ6xZHe7enP7u20VTDu08hwagleW_rYArq_6Q",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "KarposBank-y4vte",
      name: "karposbank",
      primaryColor: "#F33D0D",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/KarposBank/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY0NjM0NDIxMzg0NCwiYXVkIjoiS2FycG9zQmFuayIsInRlbmFudElkIjoiS2FycG9zQmFuay15NHZ0ZSJ9.QZs1lGEwaQiJJlGz_DUEKqQT87U_iT19NIrDDi_RCL-6K65IHvFNZgNLLv8NCbvQ6JopgcDecTQFTyObbXXrtQ",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "VerseBank-uws71",
      name: "versebank",
      primaryColor: "#A72E7C",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/VerseBank/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY0NDkzMzEwNjgyNCwiYXVkIjoiVmVyc2VCYW5rIiwidGVuYW50SWQiOiJWZXJzZUJhbmstdXdzNzEifQ.P0xuLoa0Fq5LXvuwqpSgQMUxR8TcRPnaeE8r9J9IsLmbjwy0E022sPGP2CZhwwQlC2OAVNRPYzFA1ldTEDeneg",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "Bold-0i7lb",
      name: "bold",
      primaryColor: "#FE3976",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/Bold/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY0NDYxNDY1NDI4MiwiYXVkIjoiQm9sZCIsInRlbmFudElkIjoiQm9sZC0waTdsYiJ9.jfTNi4WLx6i_uyJ_sVnuooF6DKyYS7VIXKkFKV-yY9PkrsB4nLDd4f6QvpUMMEcY6QEmat4mUbAHH3mHg299Hg",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "LiveB-y1i1s",
      name: "liveb",
      primaryColor: "#0047BA",
      overPrimary: "#F6F6F6",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/LiveB/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY0NDYxMzUyNTA3NiwiYXVkIjoiTGl2ZUIiLCJ0ZW5hbnRJZCI6IkxpdmVCLXkxaTFzIn0.fEgsFNpxWUFud2dM1Z2T4D35gy4fdqMJ4moTaHGZCrqi1wSqVdaVLOLaC1iOsX5aRxAP9USIH7RzuoVJsWCx4g",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "HebromBank-nodfv",
      name: "hebrombank",
      primaryColor: "0xFFFBBA00",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/HebromBank/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY0MzM2NTI2NjE1MSwiYXVkIjoiSGVicm9tQmFuayIsInRlbmFudElkIjoiSGVicm9tQmFuay1ub2RmdiJ9.F2DV_Mxif_4kvGsoEJcqGuEo6Mo6Kvj38Qf8r3VykSQptOqCfNcvQC0LLxaySGb-E2iUVghgJDKXf0fU2D4Lbg",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "iBank-2by0q",
      name: "ibank",
      primaryColor: "0xFF213956",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/iBank/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY0MzIzMzgwNTE4MiwiYXVkIjoiaUJhbmsiLCJ0ZW5hbnRJZCI6ImlCYW5rLTJieTBxIn0.EelW6UlWDQJBbawe3ER5ysvMZcZk9-AYKBZJ84goHsK3mI2w3qYhWeg822babS7-uwPwqH6LAHqR3UWmeWvrvQ",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "ZZ8Cards-zz4jc",
      name: "008bank",
      primaryColor: "0xFF02193E",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/ZZ8Cards/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY0MzQ3NTY5MTQ1MSwiYXVkIjoiWlo4Q2FyZHMiLCJ0ZW5hbnRJZCI6IlpaOENhcmRzLXp6NGpjIn0.muEfucs6U1lXd-28BNmNUIXZ3R211Jlx3p0QuoqRyjVxNJmRQD0aQRnd9x_UtC0PoV_d7_NsykIixsybZXQJsQ",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "SancardMatera-of1ws",
      name: "sancard",
      primaryColor: "#3F64A1",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/SancardMatera/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY0MjAyODA3MjQ4NSwiYXVkIjoiU2FuY2FyZE1hdGVyYSIsInRlbmFudElkIjoiU2FuY2FyZE1hdGVyYS1vZjF3cyJ9.qPyrmdnnQd2hxVgrEXe_yyGCBQpEjHlxcBUPrYNx-TNRalP0ntu76PeUx5be91d1QHkGnDiBuSUVm2v5UcC5pg",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "BFPay-c2p5i",
      name: "bfpay",
      primaryColor: "0xFF000050",
      overPrimary: "#FFFFFF",
      icon: "https://storage.cloud.google.com/dilettapay-prod.appspot.com/tenant-assets/BFPay/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY0MzIyNDU0MDg3MSwiYXVkIjoiQkZQYXkiLCJ0ZW5hbnRJZCI6IkJGUGF5LWMycDVpIn0.2pwj1xyZPlqBvrPid0DWbIv98ocEZHy3W8w0fSktRXaHgZLV_uzJG2nopWaL5yfeYCOGd6ckNtY5fts9CPKP3A",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "SigoBank-0987r",
      name: "sigobank",
      primaryColor: "#5D00FF",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/SigoBank/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTYzOTQzOTM3NDExNCwiYXVkIjoiU2lnb0JhbmsiLCJ0ZW5hbnRJZCI6IlNpZ29CYW5rLTA5ODdyIn0.oScr_O8yiaFqgqCMKQoazAy5OdTfFdTozskzYbr55WXo39v4ip5hN38U0HIvhfXlJI6fKhZPPej0uoibv6Tb0Q",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "Noodle-dzbdi",
      name: "noodle",
      primaryColor: "#00C550",
      overPrimary: "#FFFFFF",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0ZW5hbnRJZCI6Ik5vb2RsZS1kemJkaSIsImlhdCI6MTYwNDU5NzI1NiwiYXVkIjoiTm9vZGxlIiwiaXNzIjoiRGlsZXR0YSBTb2x1dGlvbnMifQ.isQ04FNApArcb7v8U9EnTBT001OriWaj-aOKi2CLKBzI-neF6WYh2TRZTFvONQAhCMEv1ajH4-3wH34Sg8upvA",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: true,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "ElosGate-h78fe",
      name: "trybupay",
      primaryColor: "#2281C3",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/ElosGate/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTYwOTM1NDI0MzIyMSwiYXVkIjoiRWxvc2dhdGUiLCJ0ZW5hbnRJZCI6IkVsb3NnYXRlLXgybmVhIn0.CV_WMBYNj2y7o9DdjzHYPFuuqXw7fgVdC3IGThINjl_GbbwZoLuJYPz3IzcbflEm24-ZInlj_Jo4OugZKOJDPQ",
      client: "matera",
      clientSuite: true,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "LBCSistemas-x28rp",
      name: "lbcsistemas",
      primaryColor: "#004F8A",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/LBCSistemas/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTYwODczNzI0NjM4OSwiYXVkIjoiTEJDU2lzdGVtYXMiLCJ0ZW5hbnRJZCI6IkxCQ1Npc3RlbWFzLTg1ZjZrIn0.Ag35UT3tmKZs0p7tuiGVgRieA-7k4guNd0iL2si8TVEgxCDvi3o1R0vpS--d-Fx2qFN8yL_RBPlrbW7oY6bN5Q",
      client: "matera",
      clientSuite: true,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "Softcom-jq8tl",
      name: "softcom",
      primaryColor: "#F4B732",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/Softcom/icon",
      apiKey: " eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0ZW5hbnRJZCI6IlNvZnRjb20tanE4dGwiLCJpYXQiOjE2MTIzODYyNzUsImF1ZCI6IlNvZnRjb20iLCJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyJ9.FKkKDOeudfQK9EKHpFvwLf_xdSxNIWTl1ux3K0etQY0sqkvt9KnhGDu7H_coU9m_PtM9TC2zo8G8qMKs8eDbcA",
      client: "matera",
      clientSuite: true,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "Tecnospeed-vt40h",
      name: "tecnospeed",
      primaryColor: "#3535C4",
      overPrimary: "#FFFFFF",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTYxNjc2OTQxNDU4MSwiYXVkIjoiVGVjbm9zcGVlZCIsInRlbmFudElkIjoiVGVjbm9zcGVlZC13YmlnbyJ9.7yS-7GVRtdgXHj5qZjLLZaY_twSOL9KTnKu2kjZ03qrvRLZvMUSSnPTROKnwyRUWx6iRjnJB7Ezkl3cLO-6kyg",
      client: "matera",
      clientSuite: true,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "InovantiBank-jfrgi",
      name: "inovantibank",
      primaryColor: "#F2E500",
      overPrimary: "#000000",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/InovantiBank/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTYxNTMxMzM5NDE0MiwiYXVkIjoiSW5vdmFudGlCYW5rIiwidGVuYW50SWQiOiJJbm92YW50aUJhbmstcHA4bm8ifQ.sS63C3Fs93FqimWCntG0aVmet94cSls6fOSvQU4JLbnFNXioBJ-oy43WpCqsafKDzI62pbQqtYtuBSK6seVs7g",
      client: "matera",
      clientSuite: true,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "Cocatrel-3tl67",
      name: "coffeepay",
      primaryColor: "#12712A",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/Cocatrel/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTYxNjQzODY5NzAyMCwiYXVkIjoiQ29jYXRyZWwiLCJ0ZW5hbnRJZCI6IkNvY2F0cmVsLWN4b3c4In0.tJevqJ0SLrzmCdKgHSJwDsxuzw_XN3GJTr147U21MStM3oeeSfWE2WLULKPs4xyYZ6SvMEllPOnd6EBzSf_Rwg",
      client: "matera",
      clientSuite: true,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "Rubi-lzqdg",
      name: "rubi",
      primaryColor: "#AC4553",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/Rubi/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY1NDcyMTQ4MTkyNSwiYXVkIjoiZGlsZXR0YXBheS1wcm9kIiwic3ViIjoiUnViaSIsImp0aSI6IjRkZWM4Mjg0LWNkMDktNDE2MS05NTZiLTFjYWY4MzE2OGMzZSIsInRlbmFudElkIjoiUnViaS1senFkZyJ9.MdrxRH0YxLDlFBP5nbzzH1YcFA_3jArNuEBCf1ZIYGQhc-_mGjeMbWWl97hye73j7ZqyqBUwGSiibkMyqB-sRg",
      client: "matera",
      clientSuite: true,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "DerevoBank-9ytl4",
      name: "derevobank",
      primaryColor: "#6366F1",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/DerevoBank/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY1NTczNzkxMjIzNCwiYXVkIjoiZGlsZXR0YXBheS1wcm9kIiwic3ViIjoiRGVyZXZvQmFuayIsImp0aSI6IjZkN2VjYjViLTc4MTItNGY2Zi05ODUzLWQ2MTRkZTA2Y2I0ZiIsInRlbmFudElkIjoiRGVyZXZvQmFuay05eXRsNCJ9.sCiZc5evWj4SuDJHMZyIiY2zDeokL-WbPFWybBOE6Z1bUXInJfcNbFYf6c71wn6Vxf_ACr2w0l4jwZNoYBuWLw",
      client: "matera",
      clientSuite: true,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "BeNine-5nldq",
      name: "aims",
      primaryColor: "#8517FA",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/dilettapay-prod.appspot.com/tenant-assets/BeNine/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY1ODUxNDc5Mjk3OSwiYXVkIjoiZGlsZXR0YXBheS1wcm9kIiwic3ViIjoiQmVOaW5lIiwianRpIjoiZjc3YTljOGEtOTFhZS00NzhmLTkzZTQtOWRkMjhhN2E2ODk2IiwidGVuYW50SWQiOiJCZU5pbmUtNW5sZHEifQ.xBlOe-D-q0Hu4ZlDIxEzMaw13GJg00rPbvJ9NeeP0phTpMhhff1lnte7Y7S24DQDB6koeKP2AMoDs8m-a8KAyA",
      client: "matera",
      clientSuite: true,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://dilettapay-prod-vpc-dot-dilettapay-prod.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    }
  ],
  apiUrl: 'https://dilettapay-api-vpc-gw-ylzaqhal4q-uc.a.run.app',
  keyEncryptAuth: 'oGTy7QtyHr48GE6re5KTckkGnbm6oo7SwRHsi2gqjapqflY9hjTAJ4GyUtt9YrSK',
  account: '168055',
  branch: '0546',
  wallet: '109',
  bank: '341',
  currency: '09'
};
