<!-- Breadcrumb -->

<div class="page-breadcrumb p-l-30 p-r-30 m-t-30">
  <div fxLayout="row wrap">
    <!-- <div fxFlex.gt-sm="60" fxFlex.gt-xs="60" fxFlex="100" class="align-self-center">
      <h4 class="page-title text-themecolor m-0">{{pageInfo?.title}}</h4>
    </div> -->
    <div fxFlex.gt-sm="40"
         fxFlex.gt-xs="40"
         fxFlex="100">

      <!-- <div class="breadcrumb d-flex align-items-center float-right float-sm-left"> -->
      <div class="breadcrumb d-flex float-sm-left">

        <!-- <ul class="list-style-none d-flex align-items-center"> -->

        <ul class="list-style-none d-flex">

          <ng-template ngFor
                       let-url
                       [ngForOf]="pageInfo?.urls"
                       let-last="last">
            <li class="breadcrumb-item"
                *ngIf="!last"
                [routerLink]="url.url">
              <a [routerLink]='url.url'>{{translateUrl(url.title)}}</a>
            </li>
            <li class="breadcrumb-item active"
                *ngIf="last">{{translateUrl(url.title)}}</li>
          </ng-template>

        </ul>

      </div>

    </div>

  </div>

</div>