import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getApiUrl } from 'src/app/core/state/general-state/general.selectors';

@Injectable({
  providedIn: 'root'
})
export class AccountTypeService {

  apiUrl: string = '';


  constructor(
    private http: HttpClient,
    private store: Store
  ) {
    this.store.select(getApiUrl).subscribe(apiUrl => this.apiUrl = apiUrl);
  }

  getClientLogedAccount(): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/v1/diletta/banking');
  }
  getClientAccounts(): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/v2/diletta/banking');
  }
}
