import { Directive, HostListener, Inject } from '@angular/core';

import { AppMenuLinkDirective } from './appmenulink.directive';

@Directive({
  selector: '[appMenuToggle]'
})
export class AppMenuAnchorDirective {
  protected navlink: AppMenuLinkDirective;

  constructor(@Inject(AppMenuLinkDirective) navlink: AppMenuLinkDirective) {
    this.navlink = navlink;
  }

  @HostListener('click', ['$event'])
  onClick(e: any) {
    this.navlink.togglePreselect();
  }

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(e: any) {
    this.navlink.preselect = false;
    this.navlink.hovered = true;
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(e: any) {
    this.navlink.hovered = false;
  }
}
