import { NgModule, SkipSelf, Optional, ModuleWithProviders } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireFunctionsModule, ORIGIN } from '@angular/fire/functions';

import { AuthService } from './auth/auth.service';
import { UserServiceConfig } from './config/user-service-config';
import { AuthGuard } from './auth/auth-guard';
import { HttpInterceptorInterceptor } from './http/http-interceptor.interceptor';

import { JwtModule } from '@auth0/angular-jwt';
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { localStorageSync } from 'ngrx-store-localstorage';
import { coreReducer } from './state/core.state';
import * as cryptojs from 'crypto-js';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [{
      'auth': {
        encrypt: state => cryptojs.AES.encrypt(JSON.stringify(state), environment.keyEncryptAuth).toString(),
        decrypt: state => JSON.parse(cryptojs.AES.decrypt(state, environment.keyEncryptAuth).toString(cryptojs.enc.Utf8))
      }
    }],
    // keys: [AUTH_STATE],
    rehydrate: true
  })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  imports: [
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem('token');
        },
      },
    }),
    StoreModule.forRoot(coreReducer, { metaReducers }),
  ],
  providers: [
    AuthService,
    UserServiceConfig,
    AuthGuard,
    { provide: SETTINGS, useValue: {} },
    { provide: ORIGIN, useValue: environment.apiUrl },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorInterceptor, multi: true },
  ],
  declarations: []
})
export class InovantiBankCoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: InovantiBankCoreModule
  ) {
    if (parentModule) {
      throw new Error('InovantiBankCoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config: UserServiceConfig): ModuleWithProviders<InovantiBankCoreModule> {
    return {
      ngModule: InovantiBankCoreModule,
      providers: [
        { provide: UserServiceConfig, useValue: config }
      ]
    };
  }
}
