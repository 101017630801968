import { Directive, HostListener, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { AppMenuLinkDirective } from './appmenulink.directive';
import { AppSubmenuLinkDirective } from './appsubmenulink.directive';

@Directive({
  selector: '[appSubmenu]'
})
export class AppSubmenuDirective {
  protected submenulinks: Array<AppSubmenuLinkDirective> = [];
  protected navlink: AppMenuLinkDirective;

  constructor(
    @Inject(AppMenuLinkDirective) navlink: AppMenuLinkDirective,
    private router: Router
  ) {
    this.navlink = navlink;
    setTimeout(() => this.checkOpenLinks());
  }

  @HostListener('closed', ['$event'])
  onClosed(e: any) {
    this.navlink.submenuClosed();
  }

  addSubmenuLink(submenulink: AppSubmenuLinkDirective): void {
    this.submenulinks.push(submenulink);
  }

  closeOtherLinks(selectedLink: AppSubmenuLinkDirective): void {
    this.submenulinks.forEach((link: AppSubmenuLinkDirective) => {
      if (link !== selectedLink) {
        link.selected = false;
      }
    });
  }

  checkOpenLinks() {
    this.submenulinks.forEach((link: AppSubmenuLinkDirective) => {
      if (this.router.url === link.partialPath) {
        link.selected = true;
        this.closeOtherLinks(link);
      }
    });
  }
}
