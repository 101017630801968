import { createAction, props } from "@ngrx/store"; import { ConfigApp } from "src/app/utils/configApp";
;

export enum GeneralActionsTypes {
  SET_ERROR_MESSAGE = '[SHARED] Set error message',
  EVENT_SUBMENU = '[SHARED] Submenu is opened ou closed',
  SET_CONFIGS = '[CONFIG] Set configs',
  SET_TENANT = '[TENANT] Set tenant',
  SET_LOGIN_KEY = '[LOGINKEY] Set login key',
  SET_PRIMARY_COLOR = '[TENANT] Set primary color',
  SET_APP_NAME = '[TENANT] Set app name',
  SET_API_URL = '[TENANT] Set api url',
  SET_APP_ID = '[TENANT] Set app id'

}

export const setConfigs = createAction(
  GeneralActionsTypes.SET_CONFIGS,
  props<{ configs: ConfigApp }>()
);

export const setLoginKey = createAction(
  GeneralActionsTypes.SET_LOGIN_KEY,
  props<{ loginKey: string }>()
);

export const setAppName = createAction(
  GeneralActionsTypes.SET_APP_NAME,
  props<{ appName: string }>()
);

export const setApiUrl = createAction(
  GeneralActionsTypes.SET_API_URL,
  props<{ apiUrl: string }>()
);

export const setAppId = createAction(
  GeneralActionsTypes.SET_APP_ID,
  props<{ appId: string }>()
);

export const setPrimaryColor = createAction(
  GeneralActionsTypes.SET_PRIMARY_COLOR,
  props<{ primaryColor: string }>()
);

export const setErrorMessage = createAction(
  GeneralActionsTypes.SET_ERROR_MESSAGE,
  props<{ message: string }>()
)

export const setEventSubmenu = createAction(
  GeneralActionsTypes.EVENT_SUBMENU,
  props<{ eventName: string }>()
)