import {
  Directive,
  HostBinding,
  Inject,
  Input,
  OnInit,
  OnDestroy
} from '@angular/core';

import { AppSubmenuDirective } from './appsubmenu.directive';

@Directive({
  selector: '[appSubmenuLink]'
})
export class AppSubmenuLinkDirective implements OnInit, OnDestroy {
  protected _selected = false;
  protected _hovered = false;
  protected submenu: AppSubmenuDirective;

  @Input()
  public partialPath: string;

  @HostBinding('class.selected')
  @Input()
  get selected(): boolean {
    return this._selected;
  }

  set selected(value: boolean) {
    this._selected = value;
    if (value) {
      this.submenu.closeOtherLinks(this);
    }
  }

  @HostBinding('class.hovered')
  @Input()
  get hovered(): boolean {
    return this._hovered;
  }

  set hovered(value: boolean) {
    this._hovered = value;
  }

  constructor(@Inject(AppSubmenuDirective) submenu: AppSubmenuDirective) {
    this.submenu = submenu;
    this.partialPath = '';
  }

  ngOnInit(): any {
    this.submenu.addSubmenuLink(this);
  }

  ngOnDestroy(): any { }
}
