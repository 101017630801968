import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuItems } from './menu-items/menu-items';

import {
  AppMenuAnchorDirective,
  AppMenuLinkDirective,
  AppMenuDirective,
  AppSubmenuAnchorDirective,
  AppSubmenuLinkDirective,
  AppSubmenuDirective,
} from './sidebar-directive';
import { LoadingComponent } from './loading/loading.component';
import { FormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { NgBrazil } from 'ng-brazil';
import { StepComponent } from './step/step.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Endpoints } from './endpoints/endpoints';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TextMaskModule,
    NgBrazil,
    FlexLayoutModule,
    TranslateModule
  ],
  declarations: [
    AppMenuAnchorDirective,
    AppMenuLinkDirective,
    AppMenuDirective,
    AppSubmenuAnchorDirective,
    AppSubmenuLinkDirective,
    AppSubmenuDirective,
    LoadingComponent,
    StepComponent,
  ],
  exports: [
    AppMenuAnchorDirective,
    AppMenuLinkDirective,
    AppMenuDirective,
    AppSubmenuAnchorDirective,
    AppSubmenuLinkDirective,
    AppSubmenuDirective,
    LoadingComponent,
    FormsModule,
    TextMaskModule,
    NgBrazil,
    StepComponent,
    TranslateModule
  ],
  providers: [MenuItems, Endpoints]
})
export class SharedModule { }
