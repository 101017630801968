import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loadingHash: { [id: string]: number };
  sla:string='12';
  constructor() {
    this.loadingHash = {};
  }

  isLoading(): boolean {
    if (this.loadingHash['loading-general'] === undefined) {
      return false;
    }
    return (this.loadingHash['loading-general'] > 0);
  }

  initializeLoading() {
    this.loadingHash['loading-general'] = 1;
  }

  finalizeLoading() {
    this.loadingHash['loading-general'] = 0;
  }
}
