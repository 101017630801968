import { createFeatureSelector, createSelector } from "@ngrx/store"
import { AuthState } from "./auth.state";

export const AUTH_STATE = 'auth';

const getAuthState = createFeatureSelector<AuthState>(AUTH_STATE);

export const getToken = createSelector(getAuthState, (state) => {
  return state.tokens ? state.tokens.authentication_token : null
})

export const getLoggedUser = createSelector(getAuthState, (state) => {
  return state.client ?? null;
})

export const getSettings = createSelector(getAuthState, (state) => {
  return state.settings ?? null;
})

export const getApiKey = createSelector(getAuthState, (state) => {
  return state.apiKey ?? null;
})

export const getTenant = createSelector(getAuthState, (state) => {
  return state.tenant;
})

export const getAccountType = createSelector(getAuthState, (state)=>{
  return state.accountType ?? null;
})

export const getLocation = createSelector(getAuthState, (state)=>{
  return state.location ?? null;
})