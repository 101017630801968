
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GeneralState } from './general.state';
export const GENERAL_STATE_NAME = 'general';

const getGeneralState = createFeatureSelector<GeneralState>(GENERAL_STATE_NAME);

export const getErrorMessage = createSelector(getGeneralState, (state) => {
  return state.errorMessage;
});

export const getEventSubmenu = createSelector(getGeneralState, (state) => {
  return state.eventSubmenuName;
});

export const getConfigs = createSelector(getGeneralState, (state) => {
  return state.configApp;
})

export const getLoginKey = createSelector(getGeneralState, (state) => {
  return state.loginKey;
})

export const getAppName = createSelector(getGeneralState, (state) => {
  return state.appName;
})

export const getApiUrl = createSelector(getGeneralState, (state) => {
  return state.apiUrl;
})

export const getAppId = createSelector(getGeneralState, (state) => {
  return state.appId;
})

export const getPrimaryColor = createSelector(getGeneralState, (state) => {
  return state.primaryColor;
})