import { ColorPalette, ColorConfig } from "../models/settings/system-settings";

export class ConfigApp {
  public static configStyle(color_palette: ColorPalette): void {
    Object.entries(color_palette).forEach(([key, value]) => {
      if (value.color_value != undefined) {
        if (value.color_value.substr(0, 4) == '0xff')
          document.documentElement.style.setProperty(`--${key}`, value.color_value.replace('0xff', '#'));
      }
    })

  }

  public static validateItemMenu(menuName: string, configs: any, tenant: any): boolean {
    if (menuName == 'recarga-telefone' && configs.recarga_de_celular.enabled) {
      return true;
    } else if (menuName == 'pagar' && configs.pagar_conta.enabled) {
      return true;
    } else if (menuName == 'pix' && configs.pix.enabled) {
      return true;
    } else if (menuName == 'transferencia' && configs.enviar_dinheiro.enabled) {
      return true;
    } else if (menuName == 'cobrar' && configs.receber_dinheiro.enabled) {
      return true;
    } else if (menuName == 'depositar' && configs.colocar_dinheiro.enabled) {
      return true;
    } else if (menuName == 'painel') {
      return true;
    } else if (menuName == 'conta-corrente') {
      return true;
    } else if (menuName == 'lotes' && configs.lotes?.enabled) {
      return true;
    } else if((menuName == 'ciano') && (tenant.includes('inovanti'))){
      return true
    }else {
      return false;
    }
  }

  public static validateSubItemMenu(menuName: string, configs: any): boolean {
    if (menuName == 'extrato' && configs.extrato_completo.enabled) {
      return true;
    } else if (menuName == 'cobranca-enviada' && configs.receber_dinheiro.cobranca.lista_cobranca_enabled) {
      return true;
    } else if (menuName == 'autorizacoes' && configs.profile?.solicitacoes?.enabled) {
      return true;
    } else if ((menuName == 'pagar' || menuName == 'boleto') && configs.pagar_conta.enabled) {
      return true;
    } else if ((menuName == 'cobrar') && configs.receber_dinheiro.enabled) {
      return true;
    } else if (menuName == 'maisopcoes') {
      return true;
    } else if (menuName == 'cartao-credito' && configs.cartao_prepago.enabled) {
      return true;
    } else if ((menuName == 'pix' || menuName == 'enviar-dinheiro-pix' || menuName == 'cobranca-simples' || menuName == 'cobrar-pix') && configs.pix.enabled) {
      return true;
    } else if ((menuName == 'enviar-dinheiro-interna-ted' || menuName == 'via-dados-conta' || menuName == 'transferir') && configs.enviar_dinheiro.enabled) {
      return true;
    } else if (menuName == 'dinheiro-boleto' && configs.colocar_dinheiro.boleto_simples_enabled) {
      return true;
    } else if (menuName === 'cobrar-lote' && configs.lotes?.emissionBatch) {
      return true;
    } else if (menuName == 'ted-lotes') {
      return true;
    } else if (menuName == 'pagar-lote' && configs.lotes?.paymentBatch) {
      return true;
    } else if ((menuName == 'lotes-de-boletos-processados' || menuName == 'lotes-de-teds-processados' || menuName == 'lotes-de-boletos-emitidos') && configs.lotes?.extractBatch) {
      return true;
    } else if (menuName == 'dependent-accounts' ) {
      return true;
    } else if (menuName == 'cesta-tarifaria' ) {
      return true;
    } else if (menuName == 'cobrar-boleto' && configs.receber_dinheiro.cobranca.boleto_enabled) {
      return true;
    } else if (menuName == 'escrow-account' || menuName == 'escrow-pix' || menuName == 'escrow-ticket') {
      return true;
    } else if (menuName == 'lotes-boleto' || menuName == 'lotes-ted') {
      return true
    } else {
      return false;
    }
  }

  public static validateSubItemChildMenu(menuName: string, configs: any): boolean {
    return true
  }
}