import { Injectable } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TranslateService } from '@ngx-translate/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildItem {
  state: string;
  name: string;
  type?: string;
  subchildren?: SubChildren[];
  icon?: string;
  active?: boolean;
}

export interface Menu {
  state: string;
  module: string;
  name: string;
  type: string;
  subtype?: string;
  icon: IconProp;
  useAssets: boolean,
  assetPath?: string,
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildItem[];
}

const MENUITEMS: Menu[] = [
  {
    state: 'painel',
    module: 'admin',
    name: 'Painel',
    type: 'link',
    icon: ["fas", "chart-pie"],
    useAssets: false
  },
  {
    state: 'conta-corrente',
    module: 'admin',
    name: 'Conta',
    type: 'sub',
    icon: ["fas", "wallet"],
    useAssets: false,
    children: [
      { state: 'extrato', name: 'Extrato', type: 'link', icon: 'receipt' },
      { state: 'cobranca-enviada', name: 'Cobranças Enviadas', type: 'link', icon: 'mail-bulk' },
      { state: 'cartao-credito', name: 'Cartões da Conta', type: 'link', icon: 'credit-card' },
      { state: 'autorizacoes', name: 'Autorizações', type: 'link', icon: 'inbox' },
      { state: 'cesta-tarifaria', name: 'Cestas Tarifárias', type: 'link', icon: 'inbox' },
      { state: 'lotes-de-boletos-processados', name: 'Lotes de Boletos Processados', type: 'link', icon: 'cube' },
      { state: 'lotes-de-teds-processados', name: 'Lotes de Transferências Processadas', type: 'link', icon: 'cube' },
      { state: 'lotes-de-boletos-emitidos', name: 'Lotes de Boletos Emitidos', type: 'link', icon: 'cube' },
    ]
  },
  {
    state: 'pix',
    module: 'admin',
    name: 'Pix',
    type: 'sub',
    subtype: 'subtitle',
    icon: ["fas", "square"],
    assetPath: 'assets/icons/icon-pix-solid.svg',
    useAssets: true,
    children: [
      {
        state: 'pagar', name: 'Pagar', type: 'subtitle', icon: 'qrcode', active: true,
        subchildren: [
          { state: 'via-chave-pix', name: 'Pagar com Pix', type: 'link' },
          { state: 'pix-copia-cola', name: 'Pix Copia e Cola', type: 'link' },
        ]
      },
      {
        state: 'transferir', name: 'Transferir', type: 'subtitle', icon: 'exchange-alt', active: true,
        subchildren: [
          { state: 'enviar-dinheiro-pix', name: 'Via Chave Pix', type: 'link' },
          { state: 'via-dados-conta', name: 'Via Dados da Conta', type: 'link' },
        ]
      },
      {
        state: 'cobrar', name: 'Cobrar', type: 'subtitle', icon: 'donate', active: true,
        subchildren: [
          { state: 'cobranca-simples', name: 'Cobrança Simples', type: 'link' },
          { state: 'cobranca-avancada', name: 'Cobrança Avançada', type: 'link' },
        ]
      },
      {
        state: 'maisopcoes', name: 'Mais Opções', type: 'subtitle', icon: 'cog', active: true,
        subchildren: [
          { state: 'chaves-pix', name: 'Minhas Chaves Pix', type: 'link' },
          // { state: 'limite-pix', name: 'Limite Pix', type: 'link' },
        ]
      },
      /* {
        state: 'ajuda', name: 'Ajuda', type: 'subtitle', icon: 'headset',
        subchildren: [
          { state: 'ligar-para-central', name: 'Ligar para central', type: 'link' },
          { state: 'acesso-ao-suporte', name: 'Acesso ao suporte', type: 'link' },
        ]
      }, */
    ]
  },
  {
    state: 'lotes',
    module: 'admin',
    name: 'Lotes',
    type: 'sub',
    subtype: 'subtitle',
    icon: ["fas", "cube"],
    useAssets: false,
    children: [
      {
        state: 'lotes-boleto', name: 'Boletos', type: 'subtitle', icon: 'barcode', active: true, subchildren: [
          { state: 'pagar-boleto-lote', name: 'Pagar Lote de Boletos', type: 'link' },
          { state: 'emitir-lote-boleto', name: 'Emitir Lote de Boletos', type: 'link' },
          { state: 'lotes-de-boletos-processados', name: 'Lotes de Boletos Processados', type: 'link' },
          { state: 'lotes-de-boletos-emitidos', name: 'Lotes de Boletos Emitidos', type: 'link' },
        ]
      },
      {
        state: 'lotes-ted', name: 'Ted', type: 'subtitle', icon: 'landmark', active: true, subchildren: [
          { state: 'realizar-ted-lote', name: 'Realizar Transferências em Lote', type: 'link' },
          { state: 'lotes-de-teds-processados', name: 'Lotes de Transferências Processadas', type: 'link' },
        ]
      },
    ]
  },
  {
    state: 'pagar',
    module: 'admin',
    name: 'Pagar',
    type: 'sub',
    subtype: 'subtitle',
    icon: ["fas", "qrcode"],
    useAssets: false,
    children: [
      {
        state: 'boleto', name: 'Boleto', type: 'subtitle', icon: 'barcode', active: true, subchildren: [
          { state: 'pagar-boleto', name: 'Pagar Boleto', type: 'link' }
        ]
      },
      {
        state: 'pagar-lote', name: 'Pagamento em Lote', type: 'subtitle', icon: 'cube', active: true, subchildren: [
          { state: 'pagar-boleto-lote', name: 'Pagar Lote de Boletos', type: 'link' }
        ]
      },
      {
        state: 'pix', name: 'Pix', type: 'subtitle', icon: 'donate', active: true,
        subchildren: [
          { state: 'via-chave-pix', name: 'Pagar com Pix', type: 'link' },
          { state: 'pix-copia-cola', name: 'Pix Copia e Cola', type: 'link' },
        ]
      }
    ]
  },
  {
    state: 'transferencia',
    module: 'admin',
    name: 'Transferir ',
    type: 'sub',
    icon: ["fas", "exchange-alt"],
    useAssets: false,
    children: [
      { state: 'enviar-dinheiro-interna-ted', name: 'Transferência Interna ou TED', type: 'link', icon: 'landmark' },
      { state: 'enviar-dinheiro-pix', name: 'Via Chave Pix', type: 'link', icon: 'donate' },
      { state: 'via-dados-conta', name: 'Via Dados da Conta', type: 'link', icon: 'university' },
      { state: 'ted-lotes', name: 'Realizar Transferências em Lote', type: 'link', icon: 'cube' }
    ]
  },
  {
    state: 'cobrar',
    module: 'admin',
    name: 'Cobrar',
    type: 'sub',
    subtype: 'subtitle',
    icon: ["fas", "donate"],
    useAssets: false,
    children: [
      {
        state: 'cobrar-boleto', name: 'Boleto', type: 'subtitle', icon: 'barcode', active: true, subchildren: [
          { state: 'emitir-boleto', name: 'Emitir Boleto', type: 'link' }
        ]
      },
      {
        state: 'cobrar-lote', name: 'Lote', type: 'subtitle', icon: 'barcode', active: true, subchildren: [
          { state: 'emitir-lote-boleto', name: 'Emitir Lote de Boletos', type: 'link' }
        ]
      },
      {
        state: 'cobrar-pix', name: 'Pix', type: 'subtitle', icon: 'donate', active: true,
        subchildren: [
          { state: 'cobranca-simples', name: 'Cobrança Simples', type: 'link' },
          { state: 'cobranca-avancada', name: 'Cobrança Avançada', type: 'link' },
        ]
      },
    ]
  },
  {
    state: 'depositar',
    module: 'admin',
    name: 'Depositar',
    type: 'sub',
    icon: ["fas", "piggy-bank"],
    useAssets: false,
    children: [
      { state: 'dinheiro-boleto', name: 'Boleto', type: 'link', icon: 'barcode' },
      { state: 'cobranca-simples', name: 'Pix', type: 'link', icon: 'donate' },
    ]
  },
  {
    state: 'recarga-telefone',
    module: 'admin',
    name: 'Recarga',
    type: 'link',
    icon: ["fas", "mobile-alt"],
    useAssets: false
  },
  {
    state: 'ciano',
    module: 'admin',
    name: 'Ciano',
    type: 'link',
    icon: ["fas", "link"],
    useAssets: false
  },
]

const ESCROWMENU: Menu[] = [
  {
    state: 'painel',
    module: 'admin',
    name: 'Painel',
    type: 'link',
    icon: ["fas", "chart-pie"],
    useAssets: false
  },
  {
    state: 'conta-corrente',
    module: 'admin',
    name: 'Conta',
    type: 'sub',
    icon: ["fas", "wallet"],
    useAssets: false,
    children: [
      { state: 'extrato', name: 'Extrato', type: 'link', icon: 'receipt' },
      { state: 'cobranca-enviada', name: 'Cobranças Enviadas', type: 'link', icon: 'mail-bulk' },
    ]
  },
  {
    state: 'pix',
    module: 'admin',
    name: 'Pix',
    type: 'sub',
    subtype: 'subtitle',
    icon: ["fas", "square"],
    assetPath: 'assets/icons/icon-pix-solid.svg',
    useAssets: true,
    children: [
      {
        state: 'cobrar', name: 'Cobrar', type: 'subtitle', icon: 'donate', active: true,
        subchildren: [
          { state: 'cobranca-avancada', name: 'Cobrança Avançada', type: 'link' },
        ]

      },
      {
        state: 'maisopcoes', name: 'Mais opções', type: 'subtitle', icon: 'cog', active: true,
        subchildren: [
          { state: 'chaves-pix', name: 'Chaves Pix', type: 'link' },
          // { state: 'limite-pix', name: 'Limite Pix', type: 'link' },
        ]
      },
    ]
  },
  {
    state: 'lotes',
    module: 'admin',
    name: 'Lotes',
    type: 'sub',
    subtype: 'subtitle',
    icon: ["fas", "cube"],
    useAssets: false,
    children: [
      {
        state: 'lotes-boleto', name: 'Boletos', type: 'subtitle', icon: 'barcode', active: true, subchildren: [
          { state: 'emitir-lote-boleto', name: 'Emitir Lote de Boletos', type: 'link' },
          { state: 'lotes-de-boletos-emitidos', name: 'Lotes de Boletos Emitidos', type: 'link' },
        ]
      },
    ]
  },
  {
    state: 'cobrar',
    module: 'admin',
    name: 'Cobrar',
    type: 'sub',
    subtype: 'subtitle',
    icon: ["fas", "donate"],
    useAssets: false,
    children: [
      {
        state: 'cobrar-boleto', name: 'Boleto', type: 'subtitle', icon: 'barcode', active: true, subchildren: [
          { state: 'emitir-boleto', name: 'Emitir Boleto', type: 'link' }
        ]
      },
      {
        state: 'cobrar-lote', name: 'Lote', type: 'subtitle', icon: 'barcode', active: true, subchildren: [
          { state: 'emitir-lote-boleto', name: 'Emitir Lote boleto', type: 'link' }
        ]
      },
      {
        state: 'cobrar-pix', name: 'Pix', type: 'subtitle', icon: 'donate', active: true,
        subchildren: [
          { state: 'cobranca-avancada', name: 'Cobrança Avançada', type: 'link' },
        ]
      },
    ]
  },
]

@Injectable()
export class MenuItems {

  constructor(
    private translate: TranslateService,

  ) { }

  getMenuitem(): Menu[] {
    const MENUITEMS: Menu[] = [
      {
        state: 'painel',
        module: 'admin',
        name: this.translate.instant('Menu.Panel'),
        type: 'link',
        icon: ["fas", "chart-pie"],
        useAssets: false
      },
      {
        state: 'conta-corrente',
        module: 'admin',
        name: this.translate.instant('Menu.Account'),
        type: 'sub',
        icon: ["fas", "wallet"],
        useAssets: false,
        children: [
          { state: 'extrato', name: this.translate.instant('Menu.Statement'), type: 'link', icon: 'receipt' },
          { state: 'cobranca-enviada', name: this.translate.instant('Menu.Charges-sent'), type: 'link', icon: 'mail-bulk' },
          { state: 'cartao-credito', name: this.translate.instant('Menu.Account-cards'), type: 'link', icon: 'credit-card' },
          { state: 'autorizacoes', name: this.translate.instant('Menu.Authorizations'), type: 'link', icon: 'inbox' },
          { state: 'cesta-tarifaria', name: this.translate.instant('Menu.Fee-packages'), type: 'link', icon: 'inbox' },
          { state: 'lotes-de-boletos-processados', name: this.translate.instant('Menu.Processed-slip-batches'), type: 'link', icon: 'cube' },
          { state: 'lotes-de-teds-processados', name: this.translate.instant('Menu.Processed-transfer-batches'), type: 'link', icon: 'cube' },
          { state: 'lotes-de-boletos-emitidos', name: this.translate.instant('Menu.Issued-slip-batches'), type: 'link', icon: 'cube' },
        ]
      },
      {
        state: 'pix',
        module: 'admin',
        name: this.translate.instant('Menu.Pix'),
        type: 'sub',
        subtype: 'subtitle',
        icon: ["fas", "square"],
        assetPath: 'assets/icons/icon-pix-solid.svg',
        useAssets: true,
        children: [
          {
            state: 'pagar', name: this.translate.instant('Menu.Pay'), type: 'subtitle', icon: 'qrcode', active: true,
            subchildren: [
              { state: 'via-chave-pix', name: this.translate.instant('Menu.Pay-with-pix'), type: 'link' },
              { state: 'pix-copia-cola', name: this.translate.instant('Menu.Copy-and-paste-pix'), type: 'link' },
            ]
          },
          {
            state: 'transferir', name: this.translate.instant('Menu.Transfer'), type: 'subtitle', icon: 'exchange-alt', active: true,
            subchildren: [
              { state: 'enviar-dinheiro-pix', name: this.translate.instant('Menu.Send-money-via-pix'), type: 'link' },
              { state: 'via-dados-conta', name: this.translate.instant('Menu.Send-money-via-account-data'), type: 'link' },
            ]
          },
          {
            state: 'cobrar', name: this.translate.instant('Menu.Charge'), type: 'subtitle', icon: 'donate', active: true,
            subchildren: [
              { state: 'cobranca-simples', name: this.translate.instant('Menu.Simple-charge'), type: 'link' },
              { state: 'cobranca-avancada', name: this.translate.instant('Menu.Advanced-charge'), type: 'link' },
            ]
          },
          {
            state: 'maisopcoes', name: this.translate.instant('Menu.More-options'), type: 'subtitle', icon: 'cog', active: true,
            subchildren: [
              { state: 'chaves-pix', name: this.translate.instant('Menu.My-pix-keys'), type: 'link' },
            ]
          },
        ]
      },
      {
        state: 'lotes',
        module: 'admin',
        name: this.translate.instant('Menu.Batches'),
        type: 'sub',
        subtype: 'subtitle',
        icon: ["fas", "cube"],
        useAssets: false,
        children: [
          {
            state: 'lotes-boleto', name: this.translate.instant('Menu.Batches'), type: 'subtitle', icon: 'barcode', active: true, subchildren: [
              { state: 'pagar-boleto-lote', name: this.translate.instant('Menu.Pay-slip-batch'), type: 'link' },
              { state: 'emitir-lote-boleto', name: this.translate.instant('Menu.Issue-slip-batch'), type: 'link' },
              { state: 'lotes-de-boletos-processados', name: this.translate.instant('Menu.Processed-slip-batches'), type: 'link' },
              { state: 'lotes-de-boletos-emitidos', name: this.translate.instant('Menu.Issued-slip-batches'), type: 'link' },
            ]
          },
          {
            state: 'lotes-ted', name: this.translate.instant('Menu.Ted'), type: 'subtitle', icon: 'landmark', active: true, subchildren: [
              { state: 'realizar-ted-lote', name: this.translate.instant('Menu.Perform-batch-transfers'), type: 'link' },
              { state: 'lotes-de-teds-processados', name: this.translate.instant('Menu.Processed-transfer-batches'), type: 'link' },
            ]
          },
        ]
      },
      {
        state: 'pagar',
        module: 'admin',
        name: this.translate.instant('Menu.Pay'),
        type: 'sub',
        subtype: 'subtitle',
        icon: ["fas", "qrcode"],
        useAssets: false,
        children: [
          {
            state: 'boleto', name: this.translate.instant('Menu.Slips'), type: 'subtitle', icon: 'barcode', active: true, subchildren: [
              { state: 'pagar-boleto', name: this.translate.instant('Menu.Pay-slip'), type: 'link' }
            ]
          },
          {
            state: 'pagar-lote', name: this.translate.instant('Menu.Batch-payment'), type: 'subtitle', icon: 'cube', active: true, subchildren: [
              { state: 'pagar-boleto-lote', name: this.translate.instant('Menu.Pay-slip-batch'), type: 'link' }
            ]
          },
          {
            state: 'pix', name: this.translate.instant('Menu.Pix'), type: 'subtitle', icon: 'donate', active: true,
            subchildren: [
              { state: 'via-chave-pix', name: this.translate.instant('Menu.Pay-with-pix'), type: 'link' },
              { state: 'pix-copia-cola', name: this.translate.instant('Menu.Copy-and-paste-pix'), type: 'link' },
            ]
          }
        ]
      },
      {
        state: 'transferencia',
        module: 'admin',
        name: this.translate.instant('Menu.Transfer'),
        type: 'sub',
        icon: ["fas", "exchange-alt"],
        useAssets: false,
        children: [
          { state: 'enviar-dinheiro-interna-ted', name: this.translate.instant('Menu.Internal-transfer-or-TED'), type: 'link', icon: 'landmark' },
          { state: 'enviar-dinheiro-pix', name: this.translate.instant('Menu.Send-money-via-pix'), type: 'link', icon: 'donate' },
          { state: 'via-dados-conta', name: this.translate.instant('Menu.Send-money-via-account-data'), type: 'link', icon: 'university' },
          { state: 'ted-lotes', name: this.translate.instant('Menu.Perform-batch-transfers'), type: 'link', icon: 'cube' }
        ]
      },
      {
        state: 'cobrar',
        module: 'admin',
        name: this.translate.instant('Menu.Charge'),
        type: 'sub',
        subtype: 'subtitle',
        icon: ["fas", "donate"],
        useAssets: false,
        children: [
          {
            state: 'cobrar-boleto', name: this.translate.instant('Menu.Slips'), type: 'subtitle', icon: 'barcode', active: true, subchildren: [
              { state: 'emitir-boleto', name: this.translate.instant('Menu.Issue-slip'), type: 'link' }
            ]
          },
          {
            state: 'cobrar-lote', name: this.translate.instant('Menu.Batches'), type: 'subtitle', icon: 'barcode', active: true, subchildren: [
              { state: 'emitir-lote-boleto', name: this.translate.instant('Menu.Issue-slip-batch'), type: 'link' }
            ]
          },
          {
            state: 'cobrar-pix', name: this.translate.instant('Menu.Pix'), type: 'subtitle', icon: 'donate', active: true,
            subchildren: [
              { state: 'cobranca-simples', name: this.translate.instant('Menu.Simple-charge'), type: 'link' },
              { state: 'cobranca-avancada', name: this.translate.instant('Menu.Advanced-charge'), type: 'link' },
            ]
          },
        ]
      },
      {
        state: 'depositar',
        module: 'admin',
        name: this.translate.instant('Menu.Deposit'),
        type: 'sub',
        icon: ["fas", "piggy-bank"],
        useAssets: false,
        children: [
          { state: 'dinheiro-boleto', name: this.translate.instant('Menu.Slip'), type: 'link', icon: 'barcode' },
          { state: 'cobranca-simples', name: this.translate.instant('Menu.Pix'), type: 'link', icon: 'donate' },
        ]
      },
      {
        state: 'recarga-telefone',
        module: 'admin',
        name: this.translate.instant('Menu.Recharge'),
        type: 'link',
        icon: ["fas", "mobile-alt"],
        useAssets: false
      },
      {
        state: 'ciano',
        module: 'admin',
        name: this.translate.instant('Menu.Ciano'),
        type: 'link',
        icon: ["fas", "link"],
        useAssets: false
      },
    ]
    return MENUITEMS;
  }
  getEscrowMenu(): Menu[] {
    const ESCROWMENU: Menu[] = [
      {
        state: 'painel',
        module: 'admin',
        name: 'Painel',
        type: 'link',
        icon: ["fas", "chart-pie"],
        useAssets: false
      },
      {
        state: 'conta-corrente',
        module: 'admin',
        name: 'Conta',
        type: 'sub',
        icon: ["fas", "wallet"],
        useAssets: false,
        children: [
          { state: 'extrato', name: 'Extrato', type: 'link', icon: 'receipt' },
          { state: 'cobranca-enviada', name: 'Cobranças Enviadas', type: 'link', icon: 'mail-bulk' },
        ]
      },
      {
        state: 'pix',
        module: 'admin',
        name: 'Pix',
        type: 'sub',
        subtype: 'subtitle',
        icon: ["fas", "square"],
        assetPath: 'assets/icons/icon-pix-solid.svg',
        useAssets: true,
        children: [
          {
            state: 'cobrar', name: 'Cobrar', type: 'subtitle', icon: 'donate', active: true,
            subchildren: [
              { state: 'cobranca-avancada', name: 'Cobrança Avançada', type: 'link' },
            ]

          },
          {
            state: 'maisopcoes', name: 'Mais opções', type: 'subtitle', icon: 'cog', active: true,
            subchildren: [
              { state: 'chaves-pix', name: 'Chaves Pix', type: 'link' },
              // { state: 'limite-pix', name: 'Limite Pix', type: 'link' },
            ]
          },
        ]
      },
      {
        state: 'lotes',
        module: 'admin',
        name: 'Lotes',
        type: 'sub',
        subtype: 'subtitle',
        icon: ["fas", "cube"],
        useAssets: false,
        children: [
          {
            state: 'lotes-boleto', name: 'Boletos', type: 'subtitle', icon: 'barcode', active: true, subchildren: [
              { state: 'emitir-lote-boleto', name: 'Emitir Lote de Boletos', type: 'link' },
              { state: 'lotes-de-boletos-emitidos', name: 'Lotes de Boletos Emitidos', type: 'link' },
            ]
          },
        ]
      },
      {
        state: 'cobrar',
        module: 'admin',
        name: 'Cobrar',
        type: 'sub',
        subtype: 'subtitle',
        icon: ["fas", "donate"],
        useAssets: false,
        children: [
          {
            state: 'cobrar-boleto', name: 'Boleto', type: 'subtitle', icon: 'barcode', active: true, subchildren: [
              { state: 'emitir-boleto', name: 'Emitir Boleto', type: 'link' }
            ]
          },
          {
            state: 'cobrar-lote', name: 'Lote', type: 'subtitle', icon: 'barcode', active: true, subchildren: [
              { state: 'emitir-lote-boleto', name: 'Emitir Lote boleto', type: 'link' }
            ]
          },
          {
            state: 'cobrar-pix', name: 'Pix', type: 'subtitle', icon: 'donate', active: true,
            subchildren: [
              { state: 'cobranca-avancada', name: 'Cobrança Avançada', type: 'link' },
            ]
          },
        ]
      },
    ]

    return ESCROWMENU;
  }
}