import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { getTenant, getToken } from '../state/auth-state/auth.selectors';
import { AuthState } from '../state/auth-state/auth.state';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  tenant: string = '';

  constructor(
    private store: Store<AuthState>,
    private authService: AuthService,
    public router: Router
  ) {
    this.store.select(getTenant).subscribe((tenant) => {
      if (tenant)
        this.tenant = tenant;
    })
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.store.select(getToken).pipe(take(1))
      .pipe(map(authToken => this.authService.isAuthenticated(authToken ?? '')))
      .pipe(tap(islogged => {
        if (!islogged) {
          this.router.navigate([`/authentication/login/${this.tenant}`]);
        }
      }));
  }
}
