<div *ngIf="!miniSideBar"
     class="company-logo"
     fxLayoutAlign="center center">
  <img *ngIf="mobileQuery.matches"
       [src]="logo"
       alt="logo"
       style="width: 176px;">
  <mat-icon class="close-menu"
            (click)="closeMenu()"
            *ngIf="!mobileQuery.matches">close</mat-icon>
</div>

<div *ngIf="miniSideBar"
     class="company-logo"
     fxLayoutAlign="center center">
  <img class="company-logo-icon"
       [src]="icon"
       alt="logo">
</div>

<div fxLayoutAlign="center center">
  <button (click)="switchLanguage('pt-BR')"
          class="language-button"
          [ngClass]="{'language-button-selected': currentLanguage === 'pt-BR'}">
    <div class="faIcon">
      <img src="../../../../assets/images/flags/BR.svg"
           alt="Português"
           [ngStyle]="{'width.px': miniSideBar ? 15 : null}">
    </div>
  </button>

  <button (click)="switchLanguage('en')"
          class="language-button"
          [ngClass]="{'language-button-selected': currentLanguage === 'en'}">
    <div class="faIcon">
      <img src="../../../../assets/images/flags/US.svg"
           alt="English"
           [ngStyle]="{'width.px': miniSideBar ? 15 : null}">
    </div>
  </button>
</div>


<mat-nav-list appMenu>
  <mat-list-item appMenuLink
                 *ngFor="let menuitem of menu"
                 routerLinkActive="selected"
                 [ngClass]="setSelectedSubmenu(formatRouterLink(menuitem.module, menuitem.state, ''))"
                 group="{{menuitem.state}}"
                 (click)="scrollToTop()">

    <a appMenuToggle
       [routerLink]="[menuitem.module, menuitem.state]"
       *ngIf="(menuitem.type === 'link') && validateItemMenu(menuitem.state)"
       [style.justify-content]="miniSideBar ? 'start' : 'unset'">
      <div class="faIcon">
        <fa-icon *ngIf="!menuitem.useAssets"
                 [icon]="menuitem.icon"
                 size="lg"></fa-icon>
        <img *ngIf="menuitem.useAssets"
             [src]="menuitem.assetPath">
      </div>
      <span>{{ menuitem.name }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}"
            *ngFor="let badge of menuitem.badge">{{ badge.value}}</span>
    </a>


    <a appMenuToggle
       href="javascript:;"
       *ngIf="menuitem.type === 'sub' && validateItemMenu(menuitem.state)"
       class="app-menu-toggle"
       [style.justify-content]="miniSideBar ? 'start' : 'unset'"
       (click)="menuTrigger.toggleMenu()"
       [ngClass]="{'escrow' : menuitem.state == 'conta-escrow'}">
      <div class="faIcon">
        <fa-icon *ngIf="!menuitem.useAssets"
                 [icon]="menuitem.icon"
                 size="lg"></fa-icon>
        <img *ngIf="menuitem.useAssets"
             [src]="menuitem.assetPath">
      </div>
      <span>{{ menuitem.name }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}"
            *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
      <mat-icon class="dd-icon">keyboard_arrow_right</mat-icon>
      <div #menuTrigger="matMenuTrigger"
           [matMenuTriggerFor]="submenu"
           (menuOpened)="onOpenedSubmenu()"
           (menuClosed)="onClosedSubmenu()"></div>
    </a>

    <mat-menu appSubmenu
              #submenu="matMenu"
              class="mat-sub-menu">

      <h3 class="title">{{ menuitem.name }}</h3>
      <div *ngIf="!menuitem.subtype">
        <content *ngFor="let childitem of menuitem.children; let j = index;">
          <a mat-menu-item
             appSubmenuLink
             [routerLink]="['/', menuitem.module, menuitem.state, childitem.state]"
             class="sub-item"
             *ngIf="validateSubItemMenu(childitem.state)"
             [ngClass]="setSelectedSubmenu(formatRouterLink(menuitem.module, menuitem.state, childitem.state))"
             [partialPath]="formatRouterLink(menuitem.module, menuitem.state, childitem.state)">
            <div appSubmenuToggle>
              <i class="fas fa-{{childitem.icon}}"
                 class="icon"></i>
              {{ childitem.name }}
            </div>
          </a>
        </content>
      </div>
      <div *ngIf="menuitem.subtype === 'subtitle'">
        <content *ngFor="let childitem of menuitem.children; let j = index;">

          <div class="subnav"
               class="mat-elevation-z0"
               *ngIf="validateSubItemMenu(childitem.state)">
            <div class="subnav-header"
                 (click)="closeHeaderSubnav($event, childitem)">
              <div>
                <i class="fas fa-{{childitem.icon}} subnav-header-icon"></i>
                <span>{{ childitem.name }}</span>
              </div>
              <i *ngIf="childitem.active"
                 class="fas fa-chevron-up subnav-icon"></i>
              <i *ngIf="!childitem.active"
                 class="fas fa-chevron-down subnav-icon"></i>
            </div>
            <div class="subnav-body"
                 *ngIf="childitem.active">
              <content *ngFor="let subchildrenItem of childitem.subchildren; let j = index;">
                <a mat-menu-item
                   appSubmenuLink
                   [routerLink]="['/', menuitem.module, menuitem.state, subchildrenItem.state]"
                   class="sub-item"
                   [ngClass]="setSelectedSubmenu(formatRouterLink(menuitem.module, menuitem.state, subchildrenItem.state))"
                   [partialPath]="formatRouterLink(menuitem.module, menuitem.state, subchildrenItem.state)"
                   (click)="track(subchildrenItem.name)">
                  <div appSubmenuToggle>
                    {{ subchildrenItem.name }}
                  </div>
                </a>
              </content>
            </div>
          </div>
        </content>
      </div>

    </mat-menu>

  </mat-list-item>

  <div [ngClass]="{'close-expand' : !miniSideBar, 'close-expand-mini' : miniSideBar}"
       (click)="menuAction()">
    <mat-icon>{{ this.miniSideBar == false ? 'keyboard_arrow_left' : 'keyboard_arrow_right' }}</mat-icon>
  </div>
</mat-nav-list>

<div style="align-self:flex-end"
     [ngClass]="{'user-profile2' : !miniSideBar, 'user-profile2-mini' : miniSideBar}"
     *ngIf="mobileQuery.matches">
  <div class="accounts"
       fxLayout="row wrap"
       fxLayoutAlign="center center">
    <div (click)="openPopUp()"
         fxFlex="90"
         fxLayoutAlign="start center">
      <img *ngIf="image"
           [src]="image"
           style="border-radius: 50%;">
      <div *ngIf="!image"
           class="user-profile-menu-no-photo">
        {{getInitialFromName()}}
      </div>
      <div>
        <span class="user-name">{{name}}</span>
        <div class="user-name">{{selectedAccount}}</div>
      </div>
    </div>
    <div (click)="menuUserTrigger.openMenu()"
         fxFlex="10"
         fxLayoutAlign="end center">
      <mat-icon>arrow_right</mat-icon>
    </div>
    <div #menuUserTrigger="matMenuTrigger"
         [matMenuTriggerFor]="submenuUser"
         (menuOpened)="onOpenedSubmenu()"
         (menuClosed)="onClosedSubmenu()"></div>
  </div>
  <div *ngIf="account!=undefined && open"
       class="accounts-pop">
    <div class="account-pop">
      <div (click)="chosenAccount(i)"
           *ngFor="let account of account; let i = index">{{account}}</div>
    </div>
  </div>
</div>

<mat-menu #submenuUser=""
          class="mat-sub-menu">

  <h3 class="title">
    {{ name }}
  </h3>
  <div class="perfil"
       (click)="showPerfil()">
    <i class="fas fa-user"></i>
    {{"My-profile" | translate}}
  </div>

  <div class="perfil"
       (click)="reset('pass')">
    <i class="fas fa-key"></i>
    {{"Change-password" | translate}}
  </div>

  <div class="perfil"
       (click)="reset('pin')">
    <i class="fa fa-unlock-alt"></i>
    {{"Change-pin" | translate}}
  </div>

  <div class="perfil"
       (click)="downloadIR()">
    <i class="fas fa-file-alt"></i>
    {{getTitleIR()}}
  </div>

  <div class="exit"
       (click)="logout()">
    <i class="fas fa-sign-out-alt"></i>
    {{"Log-out" | translate}}
  </div>
</mat-menu>