import { createReducer, on } from "@ngrx/store";
import { setApiUrl, setAppId, setAppName, setConfigs, setErrorMessage, setEventSubmenu, setLoginKey, setPrimaryColor } from "./general.actions";
import { sharedInitialState } from "./general.state";

const _generalReducer = createReducer(
  sharedInitialState,

  on(setErrorMessage, (state, action) => {
    return { ...state, errorMessage: action.message }
  }),

  on(setEventSubmenu, (state, action) => {
    return { ...state, eventSubmenuName: action.eventName }
  }),

  on(setConfigs, (state, action) => {
    return { ...state, configApp: action.configs }
  }),

  on(setLoginKey, (state, action) => {
    return { ...state, loginKey: action.loginKey }
  }),

  on(setPrimaryColor, (state, action) => {
    return { ...state, primaryColor: action.primaryColor }
  }),

  on(setAppName, (state, action) => {
    return { ...state, appName: action.appName }
  }),

  on(setApiUrl, (state, action) => {
    return { ...state, apiUrl: action.apiUrl }
  }),

  on(setAppId, (state, action) => {
    return { ...state, appId: action.appId }
  })
)

export function GeneralReducer(state: any, action: any) {
  return _generalReducer(state, action)
}