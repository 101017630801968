import { IClient } from "src/app/models/authentication/client";
import { IToken } from "src/app/models/authentication/token";
import { ConfigApp } from "src/app/utils/configApp";
import { Account } from 'src/app/models/authentication/account';
import { RequestLocation } from "src/app/models/dependent-account/dependent-account";

export interface AuthState {
  client: IClient | null;
  tokens: IToken | null
  isLogged: boolean;
  error: Error | null;
  apiKey: string | null;
  tenant: string | null;
  settings: any;
  accountType: string | null;
  location: RequestLocation | null;
}

export const initialState: AuthState = {
  client: null,
  tokens: null,
  isLogged: false,
  error: null,
  apiKey: null,
  tenant: null,
  settings: null,
  accountType: null,
  location: null,
}