enum PartialRoutesName {
  AUTHENTICATION = 'authentication',
  ADMIN = 'admin',
}

export enum EnumAllRoutesName {
  ERROR = '404',
  FORGOTPASS = 'forgot',
  LOCKSCREEN = 'lockscreen',
  LOGIN = 'login/:tenant',
  LOGINOPENFINANCE = 'login/open-finance/:tenant',
  LOGINNOTENANT = 'login',
  REGISTER = 'register',
  CONSENT = 'consent',

  RESET_PIN = 'reset/pin',
  CHANGE_PIN = 'change/pin',

  RESET_PASS = 'reset/pass',
  CHANGE_PASS = 'change/pass',

  PAINEL = 'painel',

  CCEXTRATO = 'conta-corrente/extrato',
  CCAUTORIZACOES = 'conta-corrente/autorizacoes',
  CCCOBRANCAENVIADAS = 'conta-corrente/cobranca-enviada',
  CCCARTAOCREDITO = 'conta-corrente/cartao-credito',
  CCLOTESDEBOLETOSPROCESSADOS = 'conta-corrente/lotes-de-boletos-processados',
  CCLOTESDETEDPROCESSADOS = 'conta-corrente/lotes-de-teds-processados',
  CCLOTESDEBOLETOSEMITIDOS = 'conta-corrente/lotes-de-boletos-emitidos',
  CCCESTATARIFARIA = 'conta-corrente/cesta-tarifaria',

  PIXLERQRCODE = 'pix/ler-qrcode',
  PIXCOPIACOLA = 'pix/pix-copia-cola',
  PIXTRANSFVIACHAVEPIX = 'pix/via-chave-pix',
  PIXTRANSENVIAPIX = 'pix/enviar-dinheiro-pix',
  PIXTRANSFVIADADOSCONTA = 'pix/via-dados-conta',
  PIXCOBRANCASIMPLES = 'pix/cobranca-simples',
  PIXCOBRANCAAVANCADA = 'pix/cobranca-avancada',
  PIXCHAVESPIX = 'pix/chaves-pix',
  PIXLIMITEPIX = 'pix/limite-pix',
  PIXLIGARCENTRAL = 'pix/ligar-para-central',
  PIXACESSOSUPORTE = 'pix/acesso-ao-suporte',


  TRANSFCOLOCARPIX = 'transferencia/colocar-dinheiro-pix',
  TRANSFCOLOCARBOLETO = 'transferencia/colocar-dinheiro-boleto',
  TRANSFRECEBERPIXCOBRANCASIMPLES = 'transferencia/receber-dinheiro-pix-cobranca-simples',
  TRANSFRECEBERPIXCOBRANCAAVANCADA = 'transferencia/receber-dinheiro-pix-cobranca-avancada',
  TRANSFRECEBEREMITIRBOLETO = 'transferencia/receber-dinheiro-emitir-boleto',
  TRANSFENVIARPIX = 'transferencia/enviar-dinheiro-pix',
  TRANSFERENCIAPORCONDAVIAPIX = 'transferencia/via-dados-conta',
  TRANSFENVIARINTERNOTED = 'transferencia/enviar-dinheiro-interna-ted',
  TRANSFENVIARPAGARBOLETO = 'transferencia/enviar-dinheiro-pagar-boleto',
  TRANSFLOTETED = 'transferencia/ted-lotes',

  PAGARBOLETO = 'pagar/pagar-boleto',
  PAGARVIACHAVEPIX = 'pagar/via-chave-pix',
  PAGARPIXCOPIAECOLA = 'pagar/pix-copia-cola',
  PAGARLOTEBOLETO = 'pagar/pagar-boleto-lote',


  DEPOSITAREMITIRBOLETO = 'depositar/dinheiro-boleto',
  DEPOSITARPIXCOBRANCASIMPLES = 'depositar/cobranca-simples',

  COBRAREMITIRBOLETO = 'cobrar/emitir-boleto',
  COBRARPIXCOBRANCASIMPLES = 'cobrar/cobranca-simples',
  COBRARPIXCOBRANCAAVANCADA = 'cobrar/cobranca-avancada',
  COBRARLOTEBOLETO = 'cobrar/emitir-lote-boleto',

  ESCROWEMITIRBOLETO = 'conta-escrow/emitir-boleto',
  ESCROWECOBRANCAAVANCADA = 'conta-escrow/cobranca-avancada',
  ESCROWCHAVESPIX = 'conta-escrow/chaves-pix',
  ESCROWCONTA = 'conta-escrow/conta-escrow',
  ESCROWPAINEL = 'conta-escrow/painel',
  ESCROWCOBRANCASENVIADAS = 'conta-escrow/cobrancas-enviadas',

  LOTESPAGARBOLETO = 'lotes/pagar-boleto-lote',
  LOTESEMITIRBOLETO = 'lotes/emitir-lote-boleto',
  LOTESBOLETOSPROCESSADOS = 'lotes/lotes-de-boletos-processados',
  LOTESBOLETOSEMITIDOS = 'lotes/lotes-de-boletos-emitidos',
  LOTESTRANSFERIR = 'lotes/ted-lotes',
  LOTESREALIZARTED = 'lotes/realizar-ted-lote',
  LOTESTEDPROCESSADOS = 'lotes/lotes-de-teds-processados',

  RECARGATELEFONE = 'recarga-telefone',
  PERFIL = 'perfil',
  CIANO = 'ciano',

}

export class ConstatsRoutes {
  static ERROR = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.ERROR}`;
  static FORGOTPASS = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.FORGOTPASS}`;
  static LOCKSCREEN = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.LOCKSCREEN}`;
  static LOGIN = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.LOGIN}`;
  static LOGINOPENFINANCE = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.LOGINOPENFINANCE}`;
  static LOGINNOTENANT = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.LOGINNOTENANT}`;
  static REGISTER = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.REGISTER}`;
  static CONSENT = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.CONSENT}`;

  static RESET_PIN = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.RESET_PIN}`;
  static CHANGE_PIN = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.CHANGE_PIN}`;

  static RESET_PASS = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.RESET_PASS}`;
  static CHANGE_PASS = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.CHANGE_PASS}`;

  static PAINEL = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PAINEL}`;

  static CCEXTRATO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CCEXTRATO}`;
  static CCCOBRANCAENVIADAS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CCCOBRANCAENVIADAS}`;
  static CCCARTAOCREDITO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CCCARTAOCREDITO}`;
  static CCAUTORIZACOES = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CCAUTORIZACOES}`;
  static CCLOTESDEBOLETOSPROCESSADOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CCLOTESDEBOLETOSPROCESSADOS}`
  static CCLOTESDETEDPROCESSADOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CCLOTESDETEDPROCESSADOS}`;
  static CCLOTESDEBOLETOSEMITIDOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CCLOTESDEBOLETOSEMITIDOS}`;
  static CCCESTATARIFARIA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CCCESTATARIFARIA}`;

  static PIXLERQRCODE = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXLERQRCODE}`;
  static PIXCOPIACOLA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXCOPIACOLA}`;
  static PIXTRANSFVIACHAVEPIX = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXTRANSFVIACHAVEPIX}`;
  static PIXTRANSENVIAPIX = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXTRANSENVIAPIX}`;
  static PIXTRANSFVIADADOSCONTA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXTRANSFVIADADOSCONTA}`;
  static PIXCOBRANCASIMPLES = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXCOBRANCASIMPLES}`;
  static PIXCOBRANCAAVANCADA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXCOBRANCAAVANCADA}`;
  static PIXCHAVESPIX = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXCHAVESPIX}`;
  static PIXLIMITEPIX = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXLIMITEPIX}`;
  static PIXLIGARCENTRAL = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXLIGARCENTRAL}`;
  static PIXACESSOSUPORTE = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXACESSOSUPORTE}`;

  static LOTESPAGARBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.LOTESPAGARBOLETO}`;
  static LOTESEMITIRBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.LOTESEMITIRBOLETO}`;
  static LOTESBOLETOSPROCESSADOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.LOTESBOLETOSPROCESSADOS}`;
  static LOTESBOLETOSEMITIDOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.LOTESBOLETOSEMITIDOS}`;
  static LOTESTRANSFERIR = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.LOTESTRANSFERIR}`;
  static LOTESTEDPROCESSADOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.LOTESTEDPROCESSADOS}`;
  static LOTESREALIZARTED = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.LOTESREALIZARTED}`;

  static TRANSFCOLOCARPIX = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFCOLOCARPIX}`;
  static TRANSFCOLOCARBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFCOLOCARBOLETO}`;
  static TRANSFRECEBERPIXCOBRANCASIMPLES = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFRECEBERPIXCOBRANCASIMPLES}`;
  static TRANSFRECEBERPIXCOBRANCAAVANCADA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFRECEBERPIXCOBRANCAAVANCADA}`;
  static TRANSFRECEBEREMITIRBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFRECEBEREMITIRBOLETO}`;
  static TRANSFENVIARPIX = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFENVIARPIX}`;
  static TRANSFERENCIAPORCONDAVIAPIX = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFERENCIAPORCONDAVIAPIX}`;
  static TRANSFENVIARINTERNOTED = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFENVIARINTERNOTED}`;
  static TRANSFENVIARPAGARBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFENVIARPAGARBOLETO}`;
  static TRANSFLOTETED = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFLOTETED}`;

  static PAGARBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PAGARBOLETO}`;
  static PAGARVIACHAVEPIX = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PAGARVIACHAVEPIX}`;
  static PAGARPIXCOPIAECOLA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PAGARPIXCOPIAECOLA}`;
  static PAGARLOTEBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PAGARLOTEBOLETO}`;

  static DEPOSITAREMITIRBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.DEPOSITAREMITIRBOLETO}`;
  static DEPOSITARPIXCOBRANCASIMPLES = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.DEPOSITARPIXCOBRANCASIMPLES}`;

  static COBRAREMITIRBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.COBRAREMITIRBOLETO}`;
  static COBRARPIXCOBRANCASIMPLES = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.COBRARPIXCOBRANCASIMPLES}`;
  static COBRARPIXCOBRANCAAVANCADA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.COBRARPIXCOBRANCAAVANCADA}`;
  static COBRARLOTEBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.COBRARLOTEBOLETO}`;

  static ESCROWEMITIRBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.ESCROWEMITIRBOLETO}`;
  static ESCROWECOBRANCAAVANCADA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.ESCROWECOBRANCAAVANCADA}`;
  static ESCROWCHAVESPIX = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.ESCROWCHAVESPIX}`;
  static ESCROWCONTA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.ESCROWCONTA}`;
  static ESCROWPAINEL = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.ESCROWPAINEL}`;
  static ESCROWCOBRANCASENVIADAS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.ESCROWCOBRANCASENVIADAS}`;

  static RECARGATELEFONE = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.RECARGATELEFONE}`;
  static PERFIL = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PERFIL}`;
  static CIANO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CIANO}`;

  // static QRESTATIC = '/qrcode-estatico';
  // static QRDYNAMIC = '/dynamic';
  // static GETMONEY = 'receber-dinheiro';
  // static PUTMONEY = 'enviar-dinheiro';

  static getLastRouteName(routeName: string) {
    return routeName.substring(routeName.lastIndexOf('/') + 1);
  }
}

export enum EnumMps {
  MP = 'Daqui à 3 dias úteis',
  MP5 = 'Daqui à 5 dias úteis',
  MP10 = 'Daqui à 10 dias úteis',
  MP15 = 'Daqui à 15 dias úteis',
  MP30 = 'Daqui à 30 dias úteis',
  MP60 = 'Daqui à 60 dias úteis',
  MP90 = 'Daqui à 90 dias úteis'
}

export const constArrayMp = [
  { text: "Á vista", value: "VISTA" },
  { text: "Daqui à 1 dias útil", value: "MP1" },
  { text: "Daqui à 2 dias úteis", value: "MP2" },
  { text: "Daqui à 3 dias úteis", value: "MP" },
  { text: "Daqui à 4 dias úteis", value: "MP4" },
  { text: "Daqui à 5 dias úteis", value: "MP5" },
  { text: "Daqui à 6 dias úteis", value: "MP6" },
  { text: "Daqui à 7 dias úteis", value: "MP7" },
  { text: "Daqui à 8 dias úteis", value: "MP8" },
  { text: "Daqui à 9 dias úteis", value: "MP9" },
  { text: "Daqui à 10 dias úteis", value: "MP10" },
  { text: "Daqui à 11 dias úteis", value: "MP11" },
  { text: "Daqui à 12 dias úteis", value: "MP12" },
  { text: "Daqui à 13 dias úteis", value: "MP13" },
  { text: "Daqui à 14 dias úteis", value: "MP14" },
  { text: "Daqui à 15 dias úteis", value: "MP15" },
  { text: "Daqui à 16 dias úteis", value: "MP16" },
  { text: "Daqui à 17 dias úteis", value: "MP17" },
  { text: "Daqui à 18 dias úteis", value: "MP18" },
  { text: "Daqui à 19 dias úteis", value: "MP19" },
  { text: "Daqui à 20 dias úteis", value: "MP20" },
  { text: "Daqui à 21 dias úteis", value: "MP21" },
  { text: "Daqui à 22 dias úteis", value: "MP22" },
  { text: "Daqui à 23 dias úteis", value: "MP23" },
  { text: "Daqui à 24 dias úteis", value: "MP24" },
  { text: "Daqui à 25 dias úteis", value: "MP25" },
  { text: "Daqui à 26 dias úteis", value: "MP26" },
  { text: "Daqui à 27 dias úteis", value: "MP27" },
  { text: "Daqui à 28 dias úteis", value: "MP28" },
  { text: "Daqui à 29 dias úteis", value: "MP29" },
  { text: "Daqui à 30 dias úteis", value: "MP30" },
  { text: "Daqui à 31 dias úteis", value: "MP31" },
  { text: "Daqui à 32 dias úteis", value: "MP32" },
  { text: "Daqui à 33 dias úteis", value: "MP33" },
  { text: "Daqui à 34 dias úteis", value: "MP34" },
  { text: "Daqui à 35 dias úteis", value: "MP35" },
  { text: "Daqui à 60 dias úteis", value: "MP60" },
  { text: "Daqui à 90 dias úteis", value: "MP90" },
];

export const constInterestsModality = [
  { code: 0, description: "Selecione a modalidade" },
  { code: 1, description: "Valor (dias corridos)" },
  { code: 2, description: "Percentual ao dia (dias corridos)" },
  { code: 3, description: "Percentual ao mês (dias corridos)" },
  { code: 4, description: "Percentual ao ano (dias corridos)" },
  { code: 5, description: "Valor (dias úteis)" },
  { code: 6, description: "Percentual ao dia (dias úteis)" },
  { code: 7, description: "Percentual ao mês (dias úteis)" },
  { code: 8, description: "Percentual ao ano (dias úteis)" }
];

export const constFinesModality = [
  { code: 0, description: "Selecione a modalidade" },
  { code: 1, description: "Valor fixo" },
  { code: 2, description: "Percentual" },
];

// unique discount
export const constDiscountModality = [
  { code: 0, description: "Selecione a modalidade" },
  { code: 1, description: "Valor Fixo até a[s] data[s] informada[s]" },
  { code: 2, description: "Percentual até a data informada" },
  { code: 3, description: "Valor por antecipação dia corrido" },
  { code: 4, description: "Valor por antecipação dia útil" },
  { code: 5, description: "Percentual por antecipação dia corrido" },
  { code: 6, description: "Percentual por antecipação dia útil" },
];

export const constRebateModality = [
  { code: 0, description: "Selecione a modalidade" },
  { code: 1, description: "Valor Fixo" },
  { code: 2, description: "Percentual" }
];

export enum EnumTransactionTypesChargeSended {
  InstantPayment = "InstantPayment",
  BoletoCobranca = "BoletoCobranca"
}

export enum EnumPaymentStatus {
  Registado = "registrado",
  Pago = "pago",
  RemovidoPeloUsuario = "removido_usuario"
}
