import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as mixpanel from 'mixpanel-browser';
import { getAppId } from '../../core/state/general-state/general.selectors';
import { setAppId } from 'src/app/core/state/general-state/general.actions';
import { forEach } from 'lodash';
import { valuesArray } from 'src/app/models/mix-panel/mix-panel-values';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  appId: any;

  constructor(
    private store: Store,
  ) {
    this.store.select(getAppId).subscribe((appid) => {
      if (appid)
        this.appId = appid;
    })
    this.init();
  }

  init(): void {
    mixpanel.init('d423e1631311fb129978b40cb2742ca2');
    mixpanel.register({ "appId": this.appId })
  }

  register(key: string, value: any): void {
    mixpanel.register({ [`${key}`]: value });
  }

  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }

}