import {
  Directive,
  HostBinding,
  Inject,
  Input,
  OnInit,
  OnDestroy
} from '@angular/core';

import { AppMenuDirective } from './appmenu.directive';

@Directive({
  selector: '[appMenuLink]'
})
export class AppMenuLinkDirective implements OnInit, OnDestroy {
  protected _selected = false;
  protected _preselect = false;
  protected _hovered = false;
  protected nav: AppMenuDirective;

  @Input()
  public group: any;

  @HostBinding('class.selected')
  @Input()
  get selected(): boolean {
    return this._selected;
  }

  set selected(value: boolean) {
    this._selected = value;
    if (value) {
      this.nav.closeOtherLinks(this);
    }
  }

  @HostBinding('class.preselect')
  @Input()
  get preselect(): boolean {
    return this._preselect;
  }

  set preselect(value: boolean) {
    this._preselect = value;
    if (value) {
      this.nav.unselectOthersLinks(this);
    }
  }

  @HostBinding('class.hovered')
  @Input()
  get hovered(): boolean {
    return this._hovered;
  }

  set hovered(value: boolean) {
    this._hovered = value;
  }

  constructor(@Inject(AppMenuDirective) nav: AppMenuDirective) {
    this.nav = nav;
  }

  ngOnInit(): any {
    this.nav.addLink(this);
  }

  ngOnDestroy(): any {
    this.nav.removeGroup(this);
  }

  togglePreselect(): any {
    this.preselect = !this.preselect;
  }

  submenuClosed(): any {
    this.preselect = false;
  }
}
