import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Store } from '@ngrx/store';
import { setEventSubmenu } from 'src/app/core/state/general-state/general.actions';
import { Router } from '@angular/router';
import { setAccountIndex } from 'src/app/core/state/auth-state/auth.actions';
import { LoginService } from 'src/app/services/login/login.service';
import { ConfigApp } from 'src/app/utils/configApp';
import { Subscription } from 'rxjs';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { getConfigs } from 'src/app/core/state/general-state/general.selectors';
import { environment } from 'src/environments/environment';
import { getTenant, getAccountType, getLoggedUser } from 'src/app/core/state/auth-state/auth.selectors';
import { LoadingService } from 'src/app/shared/loading/loading.service';
import { ConstatsRoutes } from 'src/app/utils/consts';
import { MixpanelService } from 'src/app/services/mix-panel/mix-panel.service';
import { AccountTypeService } from 'src/app/services/account-type/account-type.service';
import * as moment from 'moment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarComponent implements OnInit, OnDestroy {
  @Output() newItemEvent = new EventEmitter<boolean>();
  @Input() snavFull: any;

  miniSideBar = false;
  image: any;
  name: string = "User Name";

  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;

  sysSettings: any;
  private _mobileQueryListener: () => void;
  status = true;

  itemSelect: number[] = [];
  parentIndex = 0;
  childIndex = 0;
  configsApp: any;
  subscription: Subscription = new Subscription();
  logo: any
  tenant: string = '';
  icon: any;
  showSubnavBody: boolean = true;
  account: Array<string> = [''];
  selectedAccount: string = 'MAIN';
  open: boolean = false;
  isEscrow: boolean = false;
  currentLanguage: string = 'pt-BR';

  menu: Array<any> = [];

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private store: Store,
    private router: Router,
    private loginService: LoginService,
    private settingsService: SettingsService,
    private loadingService: LoadingService,
    private mixpanelService: MixpanelService,
    private AccountTypeService: AccountTypeService,
    private translate: TranslateService,
  ) {
    this.menu = this.menuItems.getMenuitem();
    this.mobileQuery = media.matchMedia('(min-width: 960px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.loginService.getClient().subscribe(client => {
      this.name = client.account.profile.client.name.split(" ")[0];
    });

    this.subscription.add(this.store.select(getTenant).subscribe((tenant) => {
      if (tenant)
        this.tenant = tenant;
    }));

    this.subscription.add(this.loginService.getClientProfile().subscribe(x => {
      let reader = new FileReader();
      if (x.type == 'image/png') {
        reader.addEventListener("load", () => {
          this.image = reader.result;
        })
        if (x) {
          reader.readAsDataURL(x);
        }
      }
    }));

    this.subscription.add(this.store.select(getConfigs).subscribe((configs) => {
      if (configs) {
        this.configsApp = configs;
        // assim que ele pegar as configurações do usuário, vamos recarregar as contas e montar
        // a sua lista
        this.AccountTypeService.getClientAccounts().subscribe((account) => {
          this.buildList(account);
        });
      }
    }));

    this.store.select(getAccountType).subscribe((accountType) => {
      if (accountType == "ESCROW") {
        this.isEscrow = true;
        this.selectedAccount = "Conta Escrow";
      } else if (accountType == "MAIN") {
        this.isEscrow = false;
        this.selectedAccount = "Conta Principal";
      } else if (accountType = "DEPENDENT") {
        this.isEscrow = false;
        this.selectedAccount = "Conta Dependente";
      } else {
        this.isEscrow = false;
        this.selectedAccount = accountType;
      }
    });
    this.updateSelectedAccount();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateSelectedAccount();
      this.getMenu();
    });
  }

  ngOnInit(): void {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      this.currentLanguage = savedLanguage;
      this.translate.use(savedLanguage);
    } else {
      this.currentLanguage = 'pt-BR';
      this.translate.use('pt-BR');
    }
    this.getClientLogo();
    this.getIconTenant();
    this.getMenu();
  }

  updateSelectedAccount() {
    this.AccountTypeService.getClientAccounts().subscribe((account) => {
      this.buildList(account);
      this.store.select(getAccountType).subscribe((accountType) => {
        if (accountType == "ESCROW") {
          this.isEscrow = true;
          this.selectedAccount = this.translate.instant('Escrow-account');
        } else if (accountType == "MAIN") {
          this.isEscrow = false;
          this.selectedAccount = this.translate.instant('Main-account');
        } else if (accountType = "DEPENDENT") {
          this.isEscrow = false;
          this.selectedAccount = this.translate.instant('Dependent-account');
        } else {
          this.isEscrow = false;
          this.selectedAccount = accountType;
        }
      });
    });
  }

  setClickedRow(i: number, j: number) {
    this.parentIndex = i;
    this.childIndex = j;
  }

  subClickEvent() {
    this.status = true;
  }

  scrollToTop() {
    document.querySelector('.page-wrapper')?.scroll({
      top: 0,
      left: 0
    });
  }

  getClientLogo(): void {
    this.subscription.add(this.settingsService.getLogo().subscribe(
      (result) => {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
          this.logo = reader.result;
        })
        if (result) {
          reader.readAsDataURL(result);
        }
      },

    ))
  }

  getIconTenant() {
    for (const tenant of environment.tenants) {
      if (tenant.name === this.tenant) {
        this.icon = tenant.icon;
      }
    }
  }

  validateItemMenu(menuName: string): boolean {
    return ConfigApp.validateItemMenu(menuName, this.configsApp, this.tenant);
  }

  validateSubItemMenu(menuName: string): boolean {
    return ConfigApp.validateSubItemMenu(menuName, this.configsApp);
  }

  validateSubItemChildMenu(menuName: string): boolean {
    return ConfigApp.validateSubItemChildMenu(menuName, this.configsApp);
  }

  menuAction() {
    this.miniSideBar = !this.miniSideBar;
    this.newItemEvent.emit(this.miniSideBar);
  }

  getIcon(icon: IconProp) {
    return icon;
  }

  logout() {
    this.loginService.logout();
  }

  downloadIR() {
    this.loadingService.initializeLoading();
    this.subscription.add(this.settingsService.getIncomeReports(moment().subtract(1, 'year').year().toString()).subscribe(
      (result) => {
        const linkSource = 'data:application/pdf;base64,' + result.contentFile;
        const downloadLink = document.createElement("a");
        const fileName = "informe-rendimentos-" + moment().subtract(1, 'year') + ".pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.loadingService.finalizeLoading();
      },
      (error) => {
        this.loadingService.finalizeLoading();
      }
    ))
  }

  onOpenedSubmenu() {
    this.store.dispatch(setEventSubmenu({ eventName: 'opened' }));
  }

  onClosedSubmenu() {
    this.store.dispatch(setEventSubmenu({ eventName: 'closed' }));
  }

  formatRouterLink(param1: string, param2: string, param3: string) {
    return '/' + param1 + '/' + param2 + '/' + param3;
  }

  setSelectedSubmenu(partialUrl: string) {
    if (this.router.url == partialUrl) {
      return 'selected'
    } else {
      return '';
    }
  }

  closeMenu() {
    this.snavFull.toggle();
  }

  closeHeaderSubnav(event: any, childItem: any) {
    event.stopPropagation();
    this.showSubnavBody = !this.showSubnavBody;
    childItem.active = !childItem.active;
  }

  showPerfil() {
    this.router.navigate(['admin/perfil'])
  }

  getInitialFromName(): string {
    let nameAux = (this.name) ? this.name.split(" ") : [];
    let alias = "";

    nameAux.forEach(element => {
      alias += element[0];
    });

    return alias;
  }

  hasEscrowAccount(account: any): void {
    if (account.some((el: any) => el.status == "REGULAR" && el.accountIndex == 'ESCROW')) {
      this.account.push(this.translate.instant('Escrow-account'));
    }
  }

  hasDependentAccount(account: any): void {
    if (account.some((el: any) => el.status == "REGULAR" && el.accountIndex == 'DEPENDENT')) {
      this.account.push(this.translate.instant('Dependent-account'));
    }
  }

  hasMainAccount(account: any): void {
    if (account.some((el: any) => el.status == "REGULAR" && el.accountIndex == 'MAIN')) {
      this.account.push(this.translate.instant('Main-account'))
    }
  }

  buildList(account: any) {
    this.account = [];
    account.forEach((accountItem: any) => {
      if (accountItem.status === 'REGULAR') {
        switch (accountItem.accountIndex) {
          case 'MAIN':
            this.account.push(this.translate.instant('Main-account'));
            break;
          case 'DEPENDENT':
            this.account.push(this.translate.instant('Dependent-account'));
            break;
          case 'ESCROW':
            this.account.push(this.translate.instant('Escrow-account'));
            break;
          default:
            this.account.push(accountItem.accountIndex);
            break;
        }
      }
    });
  }

  getAccountIndex(account: any) {
    switch (account) {
      case this.translate.instant('Dependent-account'):
        return 'DEPENDENT';
      case this.translate.instant('Escrow-account'):
        return 'ESCROW';
      case this.translate.instant('Main-account'):
        return 'MAIN';
      default:
        return account;
    }
  }

  chosenAccount(index: number) {
    this.open = false;
    this.selectedAccount = this.account[index];
    this.store.dispatch(setAccountIndex({ account: this.getAccountIndex(this.account[index]) }));
    this.router.navigate([ConstatsRoutes.PAINEL]);
    this.getMenu();
  }

  getMenu(): void {
    if (this.isEscrow) {
      this.menu = this.menuItems.getEscrowMenu();
    } else {
      this.menu = this.menuItems.getMenuitem();
    }
  }

  openPopUp() {
    this.open = !this.open
  }

  track(menuItem: string): void {
    if (menuItem.includes("Cobrança Avançada")) {
      this.mixpanelService.track("IB_Latest_" + menuItem);
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.subscription.unsubscribe();
  }

  getTitleIR(): string {
    return this.translate.instant('Report-income') + moment().subtract(1, 'year').year().toString();
  }

  async reset(type: string) {
    this.store.select(getLoggedUser).subscribe((user) => {
      switch (type) {
        case 'pass':
          this.router.navigate([ConstatsRoutes.RESET_PASS],
            { queryParams: { taxId: user?.taxIdentifier.taxId } });
          break;
        default:
          this.router.navigate([ConstatsRoutes.RESET_PIN],
            { queryParams: { taxId: user?.taxIdentifier.taxId } });
          break;
      }
    });
  }

  switchLanguage(language: string) {
    console.log('language: ', language);
    this.currentLanguage = language;
    this.translate.use(language);
    localStorage.setItem('language', language);
  }
}
