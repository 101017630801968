import { createReducer, on } from "@ngrx/store";
import { setAccountIndex, logoutSession, setApiKey, setClient, setSettings, setTenant, setTokens, setLocation } from "./auth.actions";
import { initialState } from "./auth.state";
import { setAccount } from './../../../admin/request/state/request-actions';

const _authReducer = createReducer(
  initialState,

  on(setTokens, (state, action) => {
    return { ...state, tokens: action.tokens }
  }),

  on(setClient, (state, action) => {
    return { ...state, client: action.client }
  }),

  on(logoutSession, (state, action) => {
    return { ...state, client: null, error: null, isLogged: false, tokens: null }
  }),

  on(setApiKey, (state, action) => {
    return { ...state, apiKey: action.apiKey }
  }),

  on(setTenant, (state, action) => {
    return { ...state, tenant: action.tenant }
  }),

  on(setSettings, (state, action) => {
    return { ...state, settings: action.settings }
  }),

  on(setAccountIndex, (state, action) => {
    return { ...state, accountType: action.account }
  }),

  on(setLocation, (state, action) => {
    return { ...state, location: action.location }
  }),
)

export function AuthReducer(state: any, action: any) {
  return _authReducer(state, action);
}