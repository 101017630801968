import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SystemSettings } from 'src/app/models/settings/system-settings';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getApiUrl } from 'src/app/core/state/general-state/general.selectors';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private syssettings: SystemSettings = {} as SystemSettings;

  apiUrl: string = '';

  constructor(
      private http: HttpClient,
      private store: Store
  ) {
      this.store.select(getApiUrl).subscribe(apiUrl => this.apiUrl = apiUrl);
  }

  /*private isSettingsEmpty() : boolean{
    return Object.entries(this.syssettings.settings).length === 0;
  }*/

  // initConfigFile(): Observable<any> {
  //   return this.http.get<any>(this.apiUrl + '/v1/diletta/tenant/files/config');
  // }

  initConfigFile(): Observable<any> {
    let params = new HttpParams().set('ccf', 'true');

    return this.http.get<any>(this.apiUrl + '/v1/diletta/tenant/files/config', { params });
  }

  initConfigTenantFile(ccf: boolean): Observable<any> {
    let params = new HttpParams().set('ccf', ccf.toString().toLowerCase());
    
    return this.http.get<any>(this.apiUrl + '/v1/diletta/tenant/config', {params});
  }


  getLogo(): Observable<any> {
    const requestOptions: Object = { responseType: 'blob' }
    return this.http.get<any>(this.apiUrl + '/v1/diletta/tenant/files/logo', requestOptions);
  }

  
  getIncomeReports(year: string): Observable<any> {
    let params = new HttpParams().set('year', year);
    return this.http.get<any>(this.apiUrl + '/v1/diletta/banking/income-reports', { params });
  }

  dontShowDialog(): Observable<any> {
    var account = {
      settings: {
        reset_password_required: false,
        reset_pin_required: true,
        use_biometrics: false,
        notification_autorization: false,
        user_notifications: {
          on_purchases: false,
          on_withdraw: false,
          news: false,
          send_over_sms: false,
          send_over_email: false
        },
        custom: false,
        contaEscrava: "Não"
      }
    }

    return this.http.post(this.apiUrl + '/v1/diletta/account/settings', { account });
  }

  getAsJSON(): JSON {
    return JSON.parse(JSON.stringify(this.syssettings));
  }

  get() {
    return this.syssettings;
  }

  set(value: SystemSettings) {
    this.syssettings = value;
  }
}
