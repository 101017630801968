const initialConfigMenu = {
  appStoreId: "",
  cartao_prepago: { enabled: true, position: 0, call_center: '', cartao_virtual: {}, cartao_fisico: {} },
  colocar_dinheiro: { boleto_simples_enabled: true, enabled: true, position: 0 },
  enviar_dinheiro: { enabled: true, position: 0 },
  extrato_completo: { enabled: true, position: 0 },
  pagar_conta: { enabled: true, position: 0 },
  pix: { enabled: true, position: 0 },
  recarga_de_celular: { enabled: true, position: 0 },
  receber_dinheiro: { cobranca: { boleto_enabled: true, lista_cobranca_enabled: true, pix_enabled: true }, enabled: true, position: 0 }
}

export interface GeneralState {
  errorMessage: string;
  eventSubmenuName: string;
  configApp: any;
  loginKey: string | null;
  primaryColor: string | null;
  appName: string;
  apiUrl: string;
  appId:string;
}

export const sharedInitialState: GeneralState = {
  errorMessage: '',
  eventSubmenuName: '',
  configApp: initialConfigMenu,
  loginKey: null,
  primaryColor: null,
  appName: '',
  apiUrl: '',
  appId:''
};