import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AppRoutes } from './app.routing';
import { NgxViacepModule } from "@brunoc/ngx-viacep";

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';

import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';

import { AppBreadcrumbComponent } from './layouts/full/breadcrumb/breadcrumb.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material-module';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

import { HttpClientModule, HttpClient } from '@angular/common/http';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBarcode, faChartPie, faCheckSquare, faCreditCard, faCube, faDonate, faExchangeAlt, faHandsHelping, faMobileAlt, faPiggyBank, faQrcode, faSquare, faWallet } from '@fortawesome/free-solid-svg-icons';

import { InovantiBankCoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { LoadingService } from './shared/loading/loading.service';

import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthGuard } from './core/auth/auth-guard';
import { UserIdleModule } from 'angular-user-idle';

export function HttpLoaderFactory(http: HttpClient) {
  console.log('http: ', http);
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    SpinnerComponent,
    AppBlankComponent,
    AppSidebarComponent,
    AppBreadcrumbComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    PerfectScrollbarModule,
    SharedModule,
    MaterialModule,
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    InovantiBankCoreModule,
    FontAwesomeModule,
    NgxViacepModule,
    UserIdleModule.forRoot({ idle: 1140, timeout: 30, ping: 5000 })
  ],
  providers: [
    LoadingService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    AuthGuard,
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(faSquare, faCheckSquare, faChartPie, faWallet, faExchangeAlt, faBarcode, faCreditCard, faMobileAlt, faDonate, faPiggyBank, faQrcode, faHandsHelping, faCube);
  }
}
