import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: any = null;
  private token: any = null;
  settings: any = null;
  apiKey: string = '';

  constructor(
    @Optional() @SkipSelf() auth: AuthService,
    private jwtHelper: JwtHelperService,
    private router: Router,
  ) {
    if (auth) {
      throw new Error('AuthService already injected');
    }
  }

  isAuthenticated(token: string): boolean {
    return !this.jwtHelper.isTokenExpired(token);
  }

  getHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': '',
        'Accept': '*/*',
        'Authorization': 'Bearer ' + this.token,
        observe: 'response'
      })
    };
  }

  logout() {
    this.token = '';
    this.user = null;
  }

  get key(): string {
    return this.apiKey;
  }

  setApiKey(apiKey: string) {
    this.apiKey = apiKey;
  }
}
