import {
  Component,
  OnInit
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, Data } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: []
})
export class AppBreadcrumbComponent implements OnInit {
  // @Input() layout;
  pageInfo: Data = Object.create(null);
  private unsubscribe$: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translate: TranslateService
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(event => {
        this.translate.get('Title').subscribe((translations: any) => {
          this.titleService.setTitle(translations[event['title']]);
          this.pageInfo = { ...event, translations: translations };

        })
      });

    this.translate.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe((event: LangChangeEvent) => {
      if (this.pageInfo && this.pageInfo.translations) {
        this.pageInfo.translations = event.translations;
        if (this.pageInfo.title && event.translations[this.pageInfo.title]) {
          this.titleService.setTitle(event.translations[this.pageInfo.title]);
        }
      }
    });
  }

  ngOnInit() { }

  translateUrl(key: string): string {
    return this.translate.instant(key);
  }
}
