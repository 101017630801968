import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IToken } from 'src/app/models/authentication/token';
import { Account, Device } from 'src/app/models/authentication/account';
import { Store } from '@ngrx/store';
import { logoutSession, setAccountIndex } from 'src/app/core/state/auth-state/auth.actions';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { getTenant } from 'src/app/core/state/auth-state/auth.selectors';
import { TenantService } from '../tenant/tenant.service';
import { getApiUrl } from 'src/app/core/state/general-state/general.selectors';
import { RequestLocation } from 'src/app/models/dependent-account/dependent-account';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  loginTimeSubject: Subject<boolean> = new Subject();
  tenant: any;
  private apiUrl: string = '';

  constructor(
    private http: HttpClient,
    private store: Store,
    private router: Router,
    private authService: AuthService,
    private tenantService: TenantService
  ) {
    this.tenant = localStorage.getItem('tenant');

    if (!this.tenant) {
      this.store.select(getTenant).subscribe(tenant => this.tenant = tenant)
    }

    this.store.select(getApiUrl).subscribe(apiUrl => this.apiUrl = apiUrl);
  }

  login(account: Account, device?: Device): Observable<IToken> {
    return this.http.post<IToken>(this.apiUrl + '/v1/diletta/account/login', { account, device });
  }

  loginOpenFinance(account: Account, device?: Device): Observable<IToken> {
    return this.http.post<IToken>(this.apiUrl + '/v1/diletta/account/login/open-finance', { account, device });
  }

  getClient(): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/v1/diletta/account');
  }

  getUserProfile(): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/v1/diletta/account/profile');
  }

  getClientSettings(): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/v1/diletta/account/settings');
  }

  getErrorMessage(error: string): string {
    return error === 'Invalid credentials.' ? "CPF, CNPJ ou senha inválidos" : "Não foi possível realizar seu login nesse momento. Por favor, tente novamente mais tarde.";
  }

  getClientProfile(): Observable<any> {
    const requestOptions: Object = { responseType: 'blob' }
    return this.http.get<any>(this.apiUrl + '/v1/diletta/account/profile/files/PROFILE', requestOptions);

  }

  logout(cancelRedirect?: boolean): void {
    this.store.dispatch(logoutSession());
    this.store.dispatch(setAccountIndex({ account: "MAIN" }))
    this.authService.logout();
    localStorage.removeItem('auth');

    if (cancelRedirect === undefined || cancelRedirect === null) {
      this.router.navigate(['/authentication/login/' + this.tenant]);
    }
  }

  async onRequestPassword(cpfCnpj: String): Promise<boolean> {
    try {
      await this.http.post(this.apiUrl + '/v1/diletta/account/request-new-password', { taxId: cpfCnpj }).toPromise();
      return true;
    } catch (error) {
      return false;
    }
  }

  getTerms(url_terms: string, type: string): Observable<any> {
    const params = new HttpParams().set('url', url_terms).set('documentType', type);

    return this.http.get(this.apiUrl + '/v1/diletta/account/legal-document', { params })
  }

  postTerms(body: any): Promise<any> {
    return this.http.post(this.apiUrl + '/v1/diletta/account/legal-document', body).toPromise();
  }

  getPinResetPassword(taxId: string): Promise<any> {
    return this.http.post(this.apiUrl + '/v2/diletta/account/request-new-password', { "taxId": taxId }).toPromise();
  }

  changePass(taxId: string, pass: string, code: string): Promise<any> {
    return this.http.put(this.apiUrl + '/v2/diletta/account/new-password', { "taxId": taxId, "password": pass, "code": code }).toPromise();
  }

  getPinResetPin(location: RequestLocation): Promise<any> {
    return this.http.post(this.apiUrl + '/v1/diletta/account/pin-request-code', { location: location }).toPromise();
  }

  changePin(code: string, pin: string): Promise<any> {
    return this.http.post(this.apiUrl + '/v1/diletta/account/pin-update', { "code": code, "newPin": pin }).toPromise();
  }

  requestConsentOpus(ccf: boolean): Observable<IToken> {
    return this.http.post<IToken>(this.apiUrl + '/v1/diletta/account/consent-opus', { ccf: ccf });
  }
}


